import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        "Reliable Investments With": "Reliable Investments With",
        "Promo": "Promo",
        "Representative": "Representative",
        "About us": "About us",
        "Investment": "Investment",
        "Contest": "Contest",
        "Make Deposit": "Make Deposit",
        "Wallet Statistic": "Wallet Statistic",
        "ENGLISH": "ENGLISH",
        "PERSIAN": "PERSIAN",
        "RUSSIAN": "RUSSIAN",
        "CHINESE": "CHINESE",
        "SPANISH": "SPANISH",
        "ARABIC": "ARABIC",
        "TURKISH": "TURKISH",

        "Get +200% Up To Your Deposit": "Get +200% Up To Your Deposit",
        "Basic Interest": "Basic Interest",
        "Every": "Every",
        "Hours": "Hours",
        "Make Investment Now": "Make Investment Now",
        "Blockchain decentralized and anonymous platform": "Blockchain decentralized and anonymous platform",
        "Totally secure income based on": "Totally secure income based on",
        "Core smart-contract": "Core smart-contract",
        "Smart-contract verified and audited by independent company": "Smart-contract verified and audited by independent company",
        "Working only with":"Working only with",
        "Core (CORE)":"Core (CORE)",
        "network":"network",
        "Minimal deposit":"Minimal deposit",
        "10 Core":"10 Core",
        "Total income":"Total income",
        "200%":"200%",
        "(deposit included)":"(deposit included)",
        "Earnings":"Earnings",
        "every moment":"every moment",
        ", withdraw any time":", withdraw any time",
        "Contract": "Contract",
        "Interest Calculator": "Interest Calculator",
        "Hourly": "Hourly",
        "Payments": "Payments",
        "You’ll Deposit": "You’ll Deposit",
        "Current profit": "Current profit",
        "Deposit Amount": "Deposit Amount",
        "Currently hourly profit": "Currently hourly profit",
        "Maximum profit": "Maximum profit",
        "ROI Reached in": "ROI Reached in",
        "days": "days",
        "Make Investment": "Make Investment",
        "CoreAvail is a decentralized, community-based, 100% fair, and smart contract-verified investment platform. It’s also the safest investment platform available.": "CoreAvail is a decentralized, community-based, 100% fair, and smart contract-verified investment platform. It’s also the safest investment platform available.",

        "We Will": "We Will",
        "Double": "Double",
        "Your Funds": "Your Funds",
        "Unlimited Earnings": "Unlimited Earnings",
        "With Always": "With Always",
        "Growing Rates": "Growing Rates",
        "We Provide investment conditions with growing percentage, depending on basic interest rate, smart-contract total balance bonus and personal": "We Provide investment conditions with growing percentage, depending on basic interest rate, smart-contract total balance bonus and personal",
        "hold-bonus": "hold-bonus",
        "Maximal available profit": "Maximal available profit",
        "+200% per every deposit": "+200% per every deposit",
        "Reliability of Core": "Reliability of Core",
        "smart-contract.": "smart-contract.",
        "Scam is impossible!": "Scam is impossible!",
        "Smart-contract technology ensures full safety of all participants funds. Nobody can steal funds, or change contract functions.": "Smart-contract technology ensures full safety of all participants funds. Nobody can steal funds, or change contract functions.",
        "Worldwide legal": "Worldwide legal",
        "company with": "company with",
        "professional team": "professional team",
        "We are the officially registered company with team of trusted professionals on blockchain market. We are working 24/7 to increase platform popularity and make it truly worldwide.": "We are the officially registered company with team of trusted professionals on blockchain market. We are working 24/7 to increase platform popularity and make it truly worldwide.",
        "Join us and get your profit!": "Join us and get your profit!",

        "Investment conditions": "Investment conditions",
        "the": "the",
        "best": "best",
        "market offer": "market offer",
        "Total invested amount (Core)": "Total invested amount (Core)",
        "Total active participants": "Total active participants",
        "Basic interest rate": "Basic interest rate",
        "+1% every 24 hours (+0.0416% hourly)": "+1% every 24 hours (+0.0416% hourly)",
        "CoreAvail smart-contract calculate profit up to every deposit since the date it was made. Every hour you will get": "CoreAvail smart-contract calculate profit up to every deposit since the date it was made. Every hour you will get",
        "every day": "every day",
        "Personal hold-bonus": "Personal hold-bonus",
        "+0.1% for every 24 hours without withdraw": "+0.1% for every 24 hours without withdraw",
        "Smart-contract calculates hold-bonus from your deposit, or last withdraw date. If you did not request payment, it will charge you an additional bonus. After 24 hours": "Smart-contract calculates hold-bonus from your deposit, or last withdraw date. If you did not request payment, it will charge you an additional bonus. After 24 hours",
        "after 48 hours": "after 48 hours",
        "after 72 hours": "after 72 hours",
        "and so on.": "and so on.",
        "Contract total amount bonus": "Contract total amount bonus",
        "+0.1% for every 3,000,000 Core on platform balance": "+0.1% for every 3,000,000 Core on platform balance",
        "Smart-contract check its current balance and charge an additional": "Smart-contract check its current balance and charge an additional",
        "up to your earnings for every 3,000,000 Core on balance.": "up to your earnings for every 3,000,000 Core on balance.",
        "CURRENT BONUS": "CURRENT BONUS",
        "Current platform balance (Core)": "Current platform balance (Core)",

        "Honest": "Honest",
        "Transparent": "Transparent",
        "The": "The",
        "Best": "Best",
        "Market Offer": "Market Offer",
        "CoreAvail platform smart-contract is published on Core blockchain. Nobody can change its rules or algorithms, even administration. This is provide our participants unconditional confidence in safety of their funds. Anyone can check smart-contract code and be sure that CoreAvail platform is honest.": "CoreAvail platform smart-contract is published on Core blockchain. Nobody can change its rules or algorithms, even administration. This is provide our participants unconditional confidence in safety of their funds. Anyone can check smart-contract code and be sure that CoreAvail platform is honest.",
        "Independent company": "Independent company",
        "HAECHI": "HAECHI",
        "Website": "Website",
        "Audition status": "Audition status",
        "Successfully passed": "Successfully passed",
        "Audit conclusion (PDF)": "Audit conclusion (PDF)",
        "Smart-contract code": "Smart-contract code",
        "Reviews and mentionings": "Reviews and mentionings",
        "Last deposits": "Last deposits",

        "Step #1": "Step #1",
        "Make deposit": "Make deposit",
        "Get": "Get",
        "Core (CORE)": "Core (CORE)",
        "We recommend to use": "We recommend to use",
        "browsers extensions, or": "browsers extensions, or",
        "mobile apps. You can get Core coins via popular exchangers.": "mobile apps. You can get Core coins via popular exchangers.",
        "SEND": "SEND",
        "Core TO OUR SMART-CONTRACT": "Core TO OUR SMART-CONTRACT",
        "Send any amount of Core on smart-contract address and start to get earnings every moment. Minimal deposit amount is": "Send any amount of Core on smart-contract address and start to get earnings every moment. Minimal deposit amount is",
        "No maximal amount.": "No maximal amount.",
        "Step #2": "Step #2",
        "Get earnings": "Get earnings",
        "Get your":"Get your",
        "Core every moment": "Core every moment",
        "You can check all your wallet information, including your deposits, earnings, withdraw and referral statistics in real time.": "You can check all your wallet information, including your deposits, earnings, withdraw and referral statistics in real time.",
        "Additional earnings with referral program": "Additional earnings with referral program",
        "3 levels of affiliate rewards": "3 levels of affiliate rewards",
        "Referral rewards comes instantly on your wallet balance and can be withdrawn any time along with earnings.": "Referral rewards comes instantly on your wallet balance and can be withdrawn any time along with earnings.",
        "Matching Commission of":"Matching Commission of",
        "on Partners Daily Income every time they make a withdrawal":"on Partners Daily Income every time they make a withdrawal",
        "1 new level is activated for each direct partner, maximum 10 levels.":"1 new level is activated for each direct partner, maximum 10 levels.",
        "Your Wallet Statistics": "Your Wallet Statistics",
        "Step #3": "Step #3",
        "Request withdraw": "Request withdraw",
        "Request": "Request",
        "Core Withdraw": "Core Withdraw",
        "Your payout will come": "Your payout will come",
        "instantly": "instantly",
        "Request withdraw from the": "Request withdraw from the",
        "same wallet": "same wallet",
        "you deposited": "you deposited",
        "All your wallet deposits and referral earnings will be withdrawn with single transaction per 1 request": "All your wallet deposits and referral earnings will be withdrawn with single transaction per 1 request",

        "Payment of deposit": "Payment of deposit",
        "Deposit Bonus is paid daily throughout the term": "Deposit Bonus is paid daily throughout the term",
        "Body of the deposit is paid at the end of the term": "Body of the deposit is paid at the end of the term",
        "The Bonus and the deposit itself are transferred to your internal balance": "The Bonus and the deposit itself are transferred to your internal balance",
        "Withdraw money from the balance to your wallet": "Withdraw money from the balance to your wallet",
        "You can make a new deposit and get more profit!": "You can make a new deposit and get more profit!",
        "Start Investment Now": "Start Investment Now",
        "Investment Conditions": "Investment Conditions",
        "Basic Interest Rate": "Basic Interest Rate",
        "Every 24 Hours": "Every 24 Hours",
        "Hourly": "Hourly",
        "Personal hold-Bonus": "Personal hold-Bonus",
        "For Every 24 Hours Without Withdraw": "For Every 24 Hours Without Withdraw",
        "Contract Total Amount Bonus": "Contract Total Amount Bonus",
        "For Every": "For Every",
        "Core  On Plateform Balance": "Core  On Plateform Balance",

        "Testimonial": "Testimonial",
        "Join Us": "Join Us",
        "Understand Golff, communicate with the team, and join the communities to build a decentralized financial world together": "Understand Golff, communicate with the team, and join the communities to build a decentralized financial world together",
        "Our Representative": "Our Representative",

        "Copyrights": "Copyrights",
        "Blockchain investment platform": "Blockchain investment platform",
        "All Rights Reserved": "All Rights Reserved",
        "Smart-contract address": "Smart-contract address",
        "Powered by": "Powered by",
        "Core blockchain": "Core blockchain",

        "Make New Deposit": "Make New Deposit",
        "Connect with your wallet app...": "Connect with your wallet app...",
        "Connect with your": "Connect with your",
        "browser extension, or": "browser extension, or",
        "mobile app. After connection you will get opportunity to make deposit (reload this page).": "mobile app. After connection you will get opportunity to make deposit (reload this page).",
        "Please, contact our support team if you cant connect.": "Please, contact our support team if you cant connect.",
        "Your upline partner wallet": "Your upline partner wallet",
        "No upline": "No upline",
        "Specify deposit": "Specify deposit",
        "Core amount here": "Core amount here",
        "Please enter amount": "Please enter amount",
        "Minimum amount is 10 Core": "Minimum amount is 10 Core",
        "IMPORTANT!": "IMPORTANT!",
        "Do not forget about blockchain fee! You should have 2-5 Core more on your wallet, or your transaction will get out of energy status!": "Do not forget about blockchain fee! You should have 2-5 Core more on your wallet, or your transaction will get out of energy status!",
        "Minimal deposit amount": "Minimal deposit amount",
        "(no maximal limits)": "(no maximal limits)",
        "We are working only with": "We are working only with",
        "Core (CORE) cryptocurrency": "Core (CORE) cryptocurrency",
        "Your deposit will be activated after": "Your deposit will be activated after",
        "1 confirmation": "1 confirmation",
        "in blockchain": "in blockchain",
        "Earnings comes every moment on your wallet balance, you can withdraw it any time you want": "Earnings comes every moment on your wallet balance, you can withdraw it any time you want",

        "Your": "Your",
        "Core wallet address": "Core wallet address",
        "Your referral link": "Your referral link",
        "Share this link with your partners to get referral rewards": "Share this link with your partners to get referral rewards",
        "You should have active deposit to unlock affiliate bonuses! You will get no rewards without active deposit.": "You should have active deposit to unlock affiliate bonuses! You will get no rewards without active deposit.",
        "Your current daily profit": "Your current daily profit",
        "Basic profit": "Basic profit",
        "Hold-bonus": "Hold-bonus",
        "Contract bonus": "Contract bonus",
        "Total invested": "Total invested",
        "Total earned": "Total earned",
        "Match bonus": "Match bonus",
        "Total withdrawn": "Total withdrawn",
        "Referral rewards": "Referral rewards",
        "Number of deposits": "Number of deposits",
        "Last deposit date": "Last deposit date",
        "no deposits": "no deposits",
        "Available withdraw balance": "Available withdraw balance",
        "Withdraw": "Withdraw",
        "1st Level": "1st Level",
        "2nd Level": "2nd Level",
        "3rd Level": "3rd Level",
        "Your referral link": "Your referral link",
        "Copy referral link": "Copy referral link",
        "Contest": "Contest",
        "COMING SOON!": "COMING SOON!"
      }
    },
    fa: {
      translations: {
        "Reliable Investments With": "سرمایه گذاری قابل اعتماد با",
        "Promo": "تبلیغاتی",
        "Representative": "نماینده",
        "About us": "درباره ما",
        "Investment": "سرمایه گذاری",
        "Contest": "مسابقه",
        "Make Deposit": "سپرده گذاری کنید",
        "Wallet Statistic": "آمار کیف پول",
        "ENGLISH": "انگلیسی",
        "PERSIAN": "فارسی",
        "RUSSIAN": "روسی",
        "CHINESE": "چینی ها",
        "SPANISH": "اسپانیایی",
        "ARABIC": "عربی",
        "TURKISH": "ترکی",
        "Get +200% Up To Your Deposit": "200٪ تا سپرده خود دریافت کنید",
        "Basic Interest": "علاقه اساسی",
        "Every": "هر",
        "Hours": "ساعت ها",
        "Make Investment Now": "اکنون سرمایه گذاری کنید",
        "Blockchain decentralized and anonymous platform": "بستر غیرمتمرکز و ناشناس بلاکچین",
        "Totally secure income based on": "درآمد کاملاً مطمئن بر اساس",
        "Core smart-contract": "قرارداد هوشمند Core",
        "Smart-contract verified and audited by independent company": "قرارداد هوشمند توسط شرکت مستقل تأیید و کنترل می شود",
        "Working only with":"کار فقط با",
        "Core (CORE)":"Core (CORE)",
        "network":"شبکه",
        "Minimal deposit":"حداقل سپرده",
        "10 Core":"10 Core",
        "Total income":"درآمد کلی",
        "200%":"200٪",
        "(deposit included)":"(واریز شامل)",
        "Earnings":"درآمد",
        "every moment":"هر لحظه",
        ", withdraw any time":"، هر زمان برداشت کنید",
        "Contract": "قرارداد",
        "Interest Calculator": "ماشین حساب بهره",
        "Hourly": "ساعتی",
        "Payments": "مبلغ پرداختی",
        "You’ll Deposit": "واریز خواهید کرد",
        "Current profit": "سود فعلی",
        "Deposit Amount": "مبلغ سپرده",
        "Currently hourly profit": "در حال حاضر سود ساعتی",
        "Maximum profit": "حداکثر سود",
        "ROI Reached in": "بازگشت سرمایه به",
        "days": "روزها",
        "Make Investment": "سرمایه گذاری کنید",
        "CoreAvail is a decentralized, community-based, 100% fair, and smart contract-verified investment platform. It’s also the safest investment platform available.": "CoreAvail یک پلت فرم سرمایه گذاری غیرمتمرکز ، مبتنی بر جامعه ، 100٪ عادلانه و هوشمند است که با قرارداد تایید شده است. همچنین امن ترین بستر سرمایه گذاری موجود است.",

        "We Will": "ما خواهیم کرد",
        "Double": "دو برابر",
        "Your Funds": "بودجه شما",
        "Unlimited Earnings": "درآمد نامحدود",
        "With Always": "با همیشه",
        "Growing Rates": "نرخ رشد",
        "We Provide investment conditions with growing percentage, depending on basic interest rate, smart-contract total balance bonus and personal": "ما شرایط سرمایه گذاری را با درصد رشد ، بسته به نرخ سود پایه ، پاداش کل قرارداد هوشمند و شخصی ، فراهم می کنیم",
        "hold-bonus": "جایزه نگه دارید",
        "Maximal available profit": "حداکثر سود موجود",
        "+200% per every deposit": "+ 200 per در هر سپرده",
        "Reliability of Core": "قابلیت اطمینان Core",
        "smart-contract.": "قرارداد هوشمند.",
        "Scam is impossible!": "کلاهبرداری غیرممکن است!",
        "Smart-contract technology ensures full safety of all participants funds. Nobody can steal funds, or change contract functions.": "فناوری قرارداد هوشمند ایمنی کامل بودجه شرکت کنندگان را تضمین می کند. هیچ کس نمی تواند سرمایه را بدزدد ، یا توافقنامه قرارداد را تغییر دهد.",
        "Worldwide legal": "در سراسر جهان حقوقی",
        "company with": "شرکت با",
        "professional team": "تیم حرفه ای",
        "We are the officially registered company with team of trusted professionals on blockchain market. We are working 24/7 to increase platform popularity and make it truly worldwide.": "ما یک شرکت رسمی ثبت شده با تیم متخصصین معتمد در بازار بلاکچین هستیم. ما در حال کار 24/7 برای افزایش محبوبیت پلت فرم و واقعی سازی آن در سراسر جهان هستیم.",
        "Join us and get your profit!": "به ما بپیوندید و سود خود را بدست آورید!",

        "Investment conditions": "شرایط سرمایه گذاری",
        "the": "",
        "best": "بهترین",
        "market offer": "پیشنهاد بازار",
        "Total invested amount (Core)": "مبلغ کل سرمایه گذاری شده (Core)",
        "Total active participants": "کل شرکت کنندگان فعال",
        "Basic interest rate": "نرخ بهره پایه",
        "+1% every 24 hours (+0.0416% hourly)": "+ 1٪ هر 24 ساعت (+ 0.0416٪ ساعتی)",
        "CoreAvail smart-contract calculate profit up to every deposit since the date it was made. Every hour you will get": "قرارداد هوشمند CoreAvail سود را از هر تاریخ سپرده گذاری تا زمان سپرده گذاری محاسبه می کند. هر ساعت دریافت خواهید کرد",
        "every day": "هر روز",
        "Personal hold-bonus": "جایزه شخصی",
        "+0.1% for every 24 hours without withdraw": "+ 0.1٪ برای هر 24 ساعت بدون برداشت",
        "Smart-contract calculates hold-bonus from your deposit, or last withdraw date. If you did not request payment, it will charge you an additional bonus. After 24 hours": "قرارداد هوشمند ، پاداش نگهداری از سپرده شما یا آخرین تاریخ برداشت را محاسبه می کند. اگر درخواست پرداخت نکردید ، یک پاداش اضافی از شما می گیرد. بعد از 24 ساعت",
        "after 48 hours": "بعد از 48 ساعت",
        "after 72 hours": "بعد از 72 ساعت",
        "and so on.": "و غیره",
        "Contract total amount bonus": "پاداش کل مبلغ قرارداد",
        "+0.1% for every 3,000,000 Core on platform balance": "+ 0.1 for برای هر 3،000،000 Core موجودی سکو",
        "Smart-contract check its current balance and charge an additional": "قرارداد هوشمند موجودی آن را بررسی کرده و هزینه اضافی را پرداخت می کنید",
        "up to your earnings for every 3,000,000 Core on balance.": "تا درآمد شما بابت هر 3،000،000 Core موجودی.",
        "CURRENT BONUS": "جایزه فعلی",
        "Current platform balance (Core)": "تراز سکوی فعلی (Core)",

        "Honest": "صادقانه",
        "Transparent": "شفاف",
        "The": "",
        "Best": "بهترین",
        "Market Offer": "پیشنهاد بازار",
        "CoreAvail platform smart-contract is published on Core blockchain. Nobody can change its rules or algorithms, even administration. This is provide our participants unconditional confidence in safety of their funds. Anyone can check smart-contract code and be sure that CoreAvail platform is honest.": "قرارداد هوشمند پلت فرم CoreAvail در بلاکچین Core منتشر شده است. هیچ کس نمی تواند قوانین یا الگوریتم های خود ، حتی مدیریت را تغییر دهد. این امر به شرکت کنندگان ما اطمینان بی قید و شرط در مورد ایمنی وجوه خود را می دهد. هرکسی می تواند کد قرارداد هوشمند را بررسی کند و مطمئن باشد که پلت فرم CoreAvail صادق است.",
        "Independent company": "شرکت مستقل",
        "HAECHI": "HAECHI",
        "Website": "سایت اینترنتی",
        "Audition status": "وضعیت استماع",
        "Successfully passed": "با موفقیت گذشت",
        "Audit conclusion (PDF)": "نتیجه حسابرسی (PDF)",
        "Smart-contract code": "کد قرارداد هوشمند",
        "Reviews and mentionings": "بررسی ها و ذکر ها",
        "Last deposits": "آخرین سپرده ها",

        "Step #1": "مرحله شماره 1",
        "Make deposit": "سپرده گذاری کنید",
        "Get": "گرفتن",
        "Core (CORE)": "Core (CORE)",
        "We recommend to use": "توصیه می کنیم از آن استفاده کنید",
        "browsers extensions, or": "پسوندهای مرورگرها ، یا",
        "mobile apps. You can get Core coins via popular exchangers.": "برنامه های تلفن همراه می توانید سکه های Core را از طریق مبدل های محبوب دریافت کنید.",
        "SEND": "ارسال",
        "Core TO OUR SMART-CONTRACT": "Core به قرارداد هوشمند ما",
        "Send any amount of Core on smart-contract address and start to get earnings every moment. Minimal deposit amount is": "هر مقدار Core را به آدرس قرارداد هوشمند ارسال کنید و هر لحظه درآمد کسب کنید. حداقل مبلغ سپرده است",
        "No maximal amount.": "بدون حداکثر مقدار",
        "Step #2": "گام 2",
        "Get earnings": "درآمد کسب کنید",
        "Get your":"بدست بیاور",
        "Core every moment": "Core هر لحظه",
        "You can check all your wallet information, including your deposits, earnings, withdraw and referral statistics in real time.": "شما می توانید تمام اطلاعات کیف پول خود ، از جمله سپرده ، درآمد ، آمار برداشت و ارجاع خود را در زمان واقعی بررسی کنید.",
        "Additional earnings with referral program": "درآمد اضافی با برنامه ارجاع",
        "3 levels of affiliate rewards": "3 سطح پاداش شرکت های وابسته",
        "Referral rewards comes instantly on your wallet balance and can be withdrawn any time along with earnings.": "جوایز مراجعه بلافاصله به تعادل کیف پول شما تعلق می گیرد و هر زمان که بخواهید همراه با درآمد قابل برداشت است.",
        "Matching Commission of":"کمیسیون تطبیق از",
        "on Partners Daily Income every time they make a withdrawal":"در شرکای درآمد روزانه هر بار که آنها برداشت می کنند",
        "1 new level is activated for each direct partner, maximum 10 levels.":"1 سطح جدید برای هر شریک مستقیم فعال می شود ، حداکثر 10 سطح.",
        "Your Wallet Statistics": "آمار کیف پول شما",
        "Step #3": "مرحله شماره 3",
        "Request withdraw": "درخواست برداشت",
        "Request": "درخواست",
        "Core Withdraw": "Core برداشت",
        "Your payout will come": "پرداخت شما خواهد آمد",
        "instantly": "فورا",
        "Request withdraw from the": "درخواست برداشت از",
        "same wallet": "همان کیف پول",
        "you deposited": "سپرده گذاری کردی",
        "All your wallet deposits and referral earnings will be withdrawn with single transaction per 1 request": "تمام واریزهای کیف پول و درآمد ارجاع شما با یک معامله در هر 1 درخواست ، برداشت می شود",

        "Payment of deposit": "پرداخت سپرده",
        "Deposit Bonus is paid daily throughout the term": "پاداش سپرده روزانه در طول مدت پرداخت می شود",
        "Body of the deposit is paid at the end of the term": "بدنه سپرده در پایان مدت پرداخت می شود",
        "The Bonus and the deposit itself are transferred to your internal balance": "پاداش و سپرده به تعادل داخلی شما منتقل می شود",
        "Withdraw money from the balance to your wallet": "از موجودی پول خود را به کیف پول خود بکشید",
        "You can make a new deposit and get more profit!": "می توانید سپرده جدیدی بگذارید و سود بیشتری بگیرید!",
        "Start Investment Now": "اکنون سرمایه گذاری را شروع کنید",
        "Investment Conditions": "شرایط سرمایه گذاری",
        "Basic Interest Rate": "نرخ بهره اصلی",
        "Every 24 Hours": "هر 24 ساعت",
        "Hourly": "ساعتی",
        "Personal hold-Bonus": "جایزه شخصی",
        "For Every 24 Hours Without Withdraw": "برای هر 24 ساعت بدون برداشت",
        "Contract Total Amount Bonus": "پاداش کل مبلغ قرارداد",
        "For Every": "برای هر",
        "Core  On Plateform Balance": "Core در تراز Plateform",

        "Testimonial": "گواهی نامه",
        "Join Us": "به ما بپیوند",
        "Understand Golff, communicate with the team, and join the communities to build a decentralized financial world together": "گلف را بفهمید ، با تیم ارتباط برقرار کنید و به جوامع بپیوندید تا با هم یک دنیای مالی غیرمتمرکز بسازیم",
        "Our Representative": "نماینده ما",

        "Copyrights": "حق چاپ",
        "Blockchain investment platform": "بستر سرمایه گذاری بلاکچین",
        "All Rights Reserved": "کلیه حقوق محفوظ است",
        "Smart-contract address": "آدرس قرارداد هوشمند",
        "Powered by": "طراحی شده توسط",
        "Core blockchain": "زنجیره بلوک Core",

        "Make New Deposit": "سپرده گذاری جدید",
        "Connect with your wallet app...": "با برنامه کیف پول خود ارتباط برقرار کنید ...",
        "Connect with your": "با خود ارتباط برقرار کنید",
        "browser extension, or": "پسوند مرورگر ، یا",
        "mobile app. After connection you will get opportunity to make deposit (reload this page).": "برنامه موبایل پس از اتصال به شما امکان واریز را خواهید یافت (این صفحه را بارگیری مجدد کنید).",
        "Please, contact our support team if you cant connect.": "اگر نمی توانید با ما تماس بگیرید ، با تیم پشتیبانی ما تماس بگیرید.",
        "Your upline partner wallet": "کیف پول شریک upline شما",
        "No upline": "بدون خط بالا",
        "Specify deposit": "سپرده را مشخص کنید",
        "Core amount here": "مبلغ Core در اینجا",
        "Please enter amount": "لطفا مقدار را وارد کنید",
        "Minimum amount is 10 Core": "لطفا مقدار را وارد کنید...",
        "IMPORTANT!": "مهم!",
        "Do not forget about blockchain fee! You should have 2-5 Core more on your wallet, or your transaction will get out of energy status!": "در مورد هزینه بلاکچین فراموش نکنید! باید 2-5 Core بیشتر در کیف پول خود داشته باشید ، در غیر این صورت معامله شما از وضعیت انرژی خارج می شود!",
        "Minimal deposit amount": "حداقل مبلغ سپرده",
        "(no maximal limits)": "(بدون حداکثر محدودیت)",
        "We are working only with": "ما فقط با آنها کار می کنیم",
        "Core (CORE) cryptocurrency": "ارز رمزنگاری شده Core (CORE)",
        "Your deposit will be activated after": "سپرده شما بعد از آن فعال می شود",
        "1 confirmation": "1 تأیید",
        "in blockchain": "در بلاکچین",
        "Earnings comes every moment on your wallet balance, you can withdraw it any time you want": "درآمد هر لحظه در تعادل کیف پول شما است ، هر زمان که بخواهید می توانید آن را برداشت کنید",

        "Your": "شما",
        "Core wallet address": "آدرس کیف پول Core",
        "Your referral link": "لینک ارجاعی شما",
        "Share this link with your partners to get referral rewards": "برای دریافت جوایز ارجاع ، این لینک را با شرکای خود به اشتراک بگذارید",
        "You should have active deposit to unlock affiliate bonuses! You will get no rewards without active deposit.": "شما باید سپرده فعال داشته باشید تا پاداش های وابسته را باز کنید! بدون سپرده فعال هیچ پاداشی دریافت نخواهید کرد.",
        "Your current daily profit": "سود روزانه شما",
        "Basic profit": "سود پایه",
        "Hold-bonus": "جایزه نگه دارید",
        "Contract bonus": "جایزه قرارداد",
        "Total invested": "کل سرمایه گذاری شده",
        "Total earned": "کل درآمد",
        "Match bonus": "مسابقه جایزه",
        "Total withdrawn": "جمع شده",
        "Referral rewards": "جوایز مراجعه",
        "Number of deposits": "تعداد سپرده ها",
        "Last deposit date": "تاریخ آخرین واریز",
        "no deposits": "بدون سپرده",
        "Available withdraw balance": "موجودی برداشت",
        "Withdraw": "کنار کشیدن",
        "1st Level": "سطح 1",
        "2nd Level": "سطح 2",
        "3rd Level": "سطح 3",
        "Your referral link": "لینک ارجاعی شما",
        "Copy referral link": "کپی لینک ارجاع",
        "Contest": "مسابقه",
        "COMING SOON!": "به زودی!"
      }
    },

    ru: {
      translations: {
        "Reliable Investments With": "Надежные инвестиции с",
        "Promo": "Промо",
        "Representative": "Представитель",
        "About us": "О нас",
        "Investment": "Инвестиции",
        "Contest": "Конкурс",
        "Make Deposit": "Сделать депозит",
        "Wallet Statistic": "Статистика кошелька",

        "ENGLISH": "АНГЛИЙСКИЙ",
        "PERSIAN": "ПЕРСИДСКИЙ",
        "RUSSIAN": "РУССКИЙ",
        "CHINESE": "КИТАЙСКИЙ ЯЗЫК",
        "SPANISH": "ИСПАНСКИЙ ЯЗЫК",
        "ARABIC": "АРАБСКИЙ",
        "TURKISH": "ТУРЕЦКИЙ",

        "Get +200% Up To Your Deposit": "Получите + 200% к вашему депозиту",
        "Basic Interest": "Основной интерес",
        "Every": "Каждые",
        "Hours": "Часы",
        "Make Investment Now": "Сделайте инвестиции сейчас",
        "Blockchain decentralized and anonymous platform": "Блокчейн децентрализованная и анонимная платформа",
        "Totally secure income based on": "Полностью безопасный доход на основе",
        "Core smart-contract": "Смарт-контракт Core",
        "Smart-contract verified and audited by independent company": "Смарт-контракт проверен и проверен независимой компанией",
        "Working only with":"Работаем только с",
        "Core (CORE)":"ТРОН (Core)",
        "network":"сеть",
        "Minimal deposit":"Минимальный депозит",
        "10 Core":"10 Core",
        "Total income":"Суммарный доход",
        "200%":"200%",
        "(deposit included)":"(депозит включен)",
        "Earnings":"Заработок",
        "every moment":"каждый момент",
        ", withdraw any time":", вывести в любое время",
        "Contract": "Договор",
        "Interest Calculator": "Калькулятор процентов",
        "Hourly": "Ежечасно",
        "Payments": "Платежи",
        "You’ll Deposit": "Вы внесете депозит",
        "Current profit": "Текущая прибыль",
        "Deposit Amount": "Сумма вклада",
        "Currently hourly profit": "Текущая почасовая прибыль",
        "Maximum profit": "Максимальная прибыль",
        "ROI Reached in": "Достигнута рентабельность инвестиций",
        "days": "дней",
        "Make Investment": "Сделать инвестиции",
        "CoreAvail is a decentralized, community-based, 100% fair, and smart contract-verified investment platform. It’s also the safest investment platform available.": "CoreAvail - это децентрализованная, основанная на сообществе, 100% честная инвестиционная платформа, подтвержденная смарт-контрактами. Это также самая безопасная доступная инвестиционная платформа.",

        "We Will": "Мы будем",
        "Double": "Двойной",
        "Your Funds": "Ваши средства",
        "Unlimited Earnings": "Неограниченный заработок",
        "With Always": "Всегда",
        "Growing Rates": "Темпы роста",
        "We Provide investment conditions with growing percentage, depending on basic interest rate, smart-contract total balance bonus and personal": "Мы предоставляем инвестиционные условия с растущим процентом в зависимости от базовой процентной ставки, бонуса на общий баланс смарт-контракта и личного",
        "hold-bonus": "холд-бонус",
        "Maximal available profit": "Максимально доступная прибыль",
        "+200% per every deposit": "+ 200% за каждый депозит",
        "Reliability of Core": "Надежность Core",
        "smart-contract.": "смарт-контракт.",
        "Scam is impossible!": "Афера невозможна!",
        "Smart-contract technology ensures full safety of all participants funds. Nobody can steal funds, or change contract functions.": "Технология смарт-контрактов обеспечивает полную сохранность средств всех участников. Никто не может украсть средства или изменить контрактные функции.",
        "Worldwide legal": "Юридические услуги по всему миру",
        "company with": "компания с",
        "professional team": "профессиональная команда",
        "We are the officially registered company with team of trusted professionals on blockchain market. We are working 24/7 to increase platform popularity and make it truly worldwide.": "Мы официально зарегистрированная компания с командой проверенных профессионалов на рынке блокчейнов. Мы работаем круглосуточно и без выходных, чтобы повысить популярность платформы и сделать ее действительно всемирной.",
        "Join us and get your profit!": "Присоединяйтесь к нам и получайте прибыль!",

        "Investment conditions": "Условия инвестирования",
        "the": "в",
        "best": "Лучший",
        "market offer": "рыночное предложение",
        "Total invested amount (Core)": "Общая инвестированная сумма (Core)",
        "Total active participants": "Всего активных участников",
        "Basic interest rate": "Базовая процентная ставка",
        "+1% every 24 hours (+0.0416% hourly)": "+ 1% каждые 24 часа (+ 0,0416% в час)",
        "CoreAvail smart-contract calculate profit up to every deposit since the date it was made. Every hour you will get": "Смарт-контракт CoreAvail рассчитывает прибыль по каждому депозиту с момента его внесения. Каждый час ты будешь получать",
        "every day": "каждый день",
        "Personal hold-bonus": "Персональный холд-бонус",
        "+0.1% for every 24 hours without withdraw": "+ 0,1% за каждые 24 часа без вывода средств",
        "Smart-contract calculates hold-bonus from your deposit, or last withdraw date. If you did not request payment, it will charge you an additional bonus. After 24 hours": "Смарт-контракт рассчитывает удержание бонуса от вашего депозита или последней даты вывода. Если вы не запрашивали оплату, вам будет начислен дополнительный бонус. Через 24 часа",
        "after 48 hours": "через 48 часов",
        "after 72 hours": "через 72 часа",
        "and so on.": "и так далее.",
        "Contract total amount bonus": "Бонус на общую сумму контракта",
        "+0.1% for every 3,000,000 Core on platform balance": "+ 0,1% за каждые 3000000 Core на балансе платформы",
        "Smart-contract check its current balance and charge an additional": "Смарт-контракт проверяет свой текущий баланс и взимает дополнительную",
        "up to your earnings for every 3,000,000 Core on balance.": "до вашего заработка за каждые 3 000 000 Core на балансе.",
        "CURRENT BONUS": "ТЕКУЩИЙ БОНУС",
        "Current platform balance (Core)": "Текущий баланс платформы (Core)",

        "Honest": "Честный",
        "Transparent": "Прозрачный",
        "The": "В",
        "Best": "Лучший",
        "Market Offer": "Рыночное предложение",
        "CoreAvail platform smart-contract is published on Core blockchain. Nobody can change its rules or algorithms, even administration. This is provide our participants unconditional confidence in safety of their funds. Anyone can check smart-contract code and be sure that CoreAvail platform is honest.": "Смарт-контракт платформы CoreAvail опубликован на блокчейне Core. Никто не может изменить его правила или алгоритмы, даже администрация. Это дает нашим участникам безоговорочную уверенность в сохранности своих средств. Любой желающий может проверить код смарт-контракта и убедиться в честности платформы CoreAvail.",
        "Independent company": "Независимая компания",
        "HAECHI": "HAECHI",
        "Website": "Интернет сайт",
        "Audition status": "Статус прослушивания",
        "Successfully passed": "Успешно сдано",
        "Audit conclusion (PDF)": "Заключение аудита (PDF)",
        "Smart-contract code": "Код смарт-контракта",
        "Reviews and mentionings": "Обзоры и упоминания",
        "Last deposits": "Последние депозиты",

        "Step #1": "Шаг 1",
        "Make deposit": "Сделать депозит",
        "Get": "Получить",
        "Core (CORE)": "ТРОН (Core)",
        "We recommend to use": "Рекомендуем использовать",
        "browsers extensions, or": "расширения для браузеров или",
        "mobile apps. You can get Core coins via popular exchangers.": "мобильные приложения. Вы можете получить монеты Core через популярные обменники.",
        "SEND": "ОТПРАВИТЬ",
        "Core TO OUR SMART-CONTRACT": "Core ДЛЯ НАШЕГО СМАРТ-КОНТРАКТА",
        "Send any amount of Core on smart-contract address and start to get earnings every moment. Minimal deposit amount is": "Отправьте любую сумму Core на адрес смарт-контракта и начните получать прибыль каждый момент. Минимальная сумма депозита составляет",
        "No maximal amount.": "Нет максимальной суммы.",
        "Step #2": "Шаг 2",
        "Get earnings": "Получайте заработок",
        "Get your":"Получить ваши",
        "Core every moment": "Core каждый момент",
        "You can check all your wallet information, including your deposits, earnings, withdraw and referral statistics in real time.": "Вы можете проверить всю информацию о своем кошельке, включая ваши депозиты, доходы, вывод средств и статистику рефералов в режиме реального времени.",
        "Additional earnings with referral program": "Дополнительный заработок по реферальной программе",
        "3 levels of affiliate rewards": "3 уровня партнерских вознаграждений",
        "Referral rewards comes instantly on your wallet balance and can be withdrawn any time along with earnings.": "Реферальные вознаграждения мгновенно поступают на баланс вашего кошелька и могут быть сняты в любое время вместе с заработком.",
        "Matching Commission of":"Соответствующая комиссия",
        "on Partners Daily Income every time they make a withdrawal":"о ежедневном доходе партнеров каждый раз, когда они выводят средства",
        "1 new level is activated for each direct partner, maximum 10 levels.":"Для каждого прямого партнера активируется 1 новый уровень, максимум 10 уровней.",
        "Your Wallet Statistics": "Статистика вашего кошелька",
        "Step #3": "Шаг 3",
        "Request withdraw": "Запрос на вывод",
        "Request": "Запрос",
        "Core Withdraw": "Вывод Core",
        "Your payout will come": "Ваша выплата придет",
        "instantly": "мгновенно",
        "Request withdraw from the": "Запрос о выходе из",
        "same wallet": "тот же кошелек",
        "you deposited": "you deposited",
        "All your wallet deposits and referral earnings will be withdrawn with single transaction per 1 request": "Все ваши депозиты в кошельке и реферальные доходы будут сняты одной транзакцией за 1 запрос.",

        "Payment of deposit": "Оплата депозита",
        "Deposit Bonus is paid daily throughout the term": "Бонус на депозит выплачивается ежедневно в течение всего срока",
        "Body of the deposit is paid at the end of the term": "Тело депозита выплачивается в конце срока",
        "The Bonus and the deposit itself are transferred to your internal balance": "",
        "Withdraw money from the balance to your wallet": "Withdraw money from the balance to your wallet",
        "You can make a new deposit and get more profit!": "Вы можете внести новый депозит и получить больше прибыли!",
        "Start Investment Now": "Начать инвестирование сейчас",
        "Investment Conditions": "Условия инвестирования",
        "Basic Interest Rate": "Базовая процентная ставка",
        "Every 24 Hours": "Каждые 24 часа",
        "Hourly": "Ежечасно",
        "Personal hold-Bonus": "Персональный холд-бонус",
        "For Every 24 Hours Without Withdraw": "За каждые 24 часа без вывода средств",
        "Contract Total Amount Bonus": "Бонус на общую сумму контракта",
        "For Every": "Для каждого",
        "Core  On Plateform Balance": "Core на балансировочной пластине",

        "Testimonial": "Отзыв",
        "Join Us": "Присоединяйтесь к нам",
        "Understand Golff, communicate with the team, and join the communities to build a decentralized financial world together": "Поймите Гольф, общайтесь с командой и присоединяйтесь к сообществам, чтобы вместе построить децентрализованный финансовый мир",
        "Our Representative": "Наш представитель",

        "Copyrights": "Авторские права",
        "Blockchain investment platform": "Инвестиционная платформа Blockchain",
        "All Rights Reserved": "Все права защищены",
        "Smart-contract address": "Адрес смарт-контракта",
        "Powered by": "Питаться от",
        "Core blockchain": "Блокчейн Core",

        "Make New Deposit": "Сделать новый депозит",
        "Connect with your wallet app...": "Подключитесь к своему кошельку ...",
        "Connect with your": "Свяжитесь со своим",
        "browser extension, or": "расширение браузера или",
        "mobile app. After connection you will get opportunity to make deposit (reload this page).": "мобильное приложение. После подключения вы получите возможность внести депозит (перезагрузите страницу).",
        "Please, contact our support team if you cant connect.": "Пожалуйста, свяжитесь с нашей службой поддержки, если у вас не получается подключиться.",
        "Your upline partner wallet": "Ваш кошелек вышестоящего партнера",
        "No upline": "Нет вышестоящего",
        "Specify deposit": "Укажите депозит",
        "Core amount here": "Сумма Core здесь",
        "Please enter amount": "Пожалуйста, введите сумму",
        "Minimum amount is 10 Core": "Минимальная сумма 10 Core",
        "IMPORTANT!": "ВАЖНЫЙ!",
        "Do not forget about blockchain fee! You should have 2-5 Core more on your wallet, or your transaction will get out of energy status!": "Не забывайте о комиссии за блокчейн! У вас должно быть на 2-5 Core больше в кошельке, иначе ваша транзакция выйдет из состояния энергопотребления!",
        "Minimal deposit amount": "Минимальная сумма депозита",
        "(no maximal limits)": "(без максимальных ограничений)",
        "We are working only with": "Мы работаем только с",
        "Core (CORE) cryptocurrency": "Криптовалюта Core (CORE)",
        "Your deposit will be activated after": "Ваш депозит будет активирован после",
        "1 confirmation": "1 подтверждение",
        "in blockchain": "в блокчейне",
        "Earnings comes every moment on your wallet balance, you can withdraw it any time you want": "Заработок поступает на баланс вашего кошелька каждый момент, вы можете вывести его в любой момент",

        "Your": "Твой",
        "Core wallet address": "Адрес кошелька Core",
        "Your referral link": "Ваша реферальная ссылка",
        "Share this link with your partners to get referral rewards": "Поделитесь этой ссылкой со своими партнерами, чтобы получать реферальные вознаграждения",
        "You should have active deposit to unlock affiliate bonuses! You will get no rewards without active deposit.": "У вас должен быть активный депозит, чтобы разблокировать партнерские бонусы! Без активного депозита вы не получите награды.",
        "Your current daily profit": "Ваша текущая дневная прибыль",
        "Basic profit": "Базовая прибыль",
        "Hold-bonus": "Холд-бонус",
        "Contract bonus": "Бонус за контракт",
        "Total invested": "Всего инвестировано",
        "Total earned": "Всего заработано",
        "Match bonus": "Бонус матча",
        "Total withdrawn": "Всего снято",
        "Referral rewards": "Реферальные награды",
        "Number of deposits": "Количество вкладов",
        "Last deposit date": "Дата последнего депозита",
        "no deposits": "нет депозитов",
        "Available withdraw balance": "Доступный баланс для вывода",
        "Withdraw": "Изымать",
        "1st Level": "1-й уровень",
        "2nd Level": "2-й уровень",
        "3rd Level": "3-й уровень",
        "Your referral link": "Ваша реферальная ссылка",
        "Copy referral link": "Скопировать реферальную ссылку",
        "Contest": "Конкурс",
        "COMING SOON!": "СКОРО БУДЕТ!"
      }
    },

    zh: {
      translations: {
        "Reliable Investments With": "可靠的投资",
        "Promo": "促销",
        "Representative": "代表",
        "About us": "关于我们",
        "Investment": "投资",
        "Contest": "比赛",
        "Make Deposit": "存入",
        "Wallet Statistic": "钱包统计",

        "ENGLISH": "英语",
        "PERSIAN": "波斯语",
        "RUSSIAN": "俄语",
        "CHINESE": "中文",
        "SPANISH": "西班牙文",
        "ARABIC": "阿拉伯",
        "TURKISH": "土耳其",
        "Get +200% Up To Your Deposit": "获得+ 200％的存款",
        "Basic Interest": "基本兴趣",
        "Every": "每一个",
        "Hours": "小时",
        "Make Investment Now": "立即进行投资",
        "Blockchain decentralized and anonymous platform": "区块链去中心化匿名平台",
        "Totally secure income based on": "完全有保障的收入基于",
        "Core smart-contract": "Core智能合约",
        "Smart-contract verified and audited by independent company": "由独立公司验证和审核的智能合约",
        "Working only with":"仅与",
        "Core (CORE)":"创（Core）",
        "network":"网络",
        "Minimal deposit":"最低存款",
        "10 Core":"10 Core",
        "Total income":"总收入",
        "200%":"200％",
        "(deposit included)":"（包括存款）",
        "Earnings":"收益",
        "every moment":"每一个瞬间",
        ", withdraw any time":"，随时撤回",
        "Contract": "合同",
        "Interest Calculator": "利息计算器",
        "Hourly": "每小时一次",
        "Payments": "付款方式",
        "You’ll Deposit": "您将存款",
        "Current profit": "当期利润",
        "Deposit Amount": "存款金额",
        "Currently hourly profit": "目前每小时的利润",
        "Maximum profit": "最大利润",
        "ROI Reached in": "达到ROI",
        "days": "天",
        "Make Investment": "进行投资",
        "CoreAvail is a decentralized, community-based, 100% fair, and smart contract-verified investment platform. It’s also the safest investment platform available.": "CoreAvail是一个分散的，基于社区的，100％公平且经过智能合约验证的投资平台。它也是可用的最安全的投资平台。",
        "We Will": "我们会",
        "Double": "双",
        "Your Funds": "您的资金",
        "Unlimited Earnings": "无限收益",
        "With Always": "与永远",
        "Growing Rates": "增长率",
        "We Provide investment conditions with growing percentage, depending on basic interest rate, smart-contract total balance bonus and personal": "我们根据基本利率，智能合约总余额奖金和个人提供不断增长的投资条件",
        "hold-bonus": "持有奖金",
        "Maximal available profit": "最大可用利润",
        "+200% per every deposit": "每笔存款+ 200％",
        "Reliability of Core": "Core的可靠性",
        "smart-contract.": "智能合约。",
        "Scam is impossible!": "骗局是不可能的！",
        "Smart-contract technology ensures full safety of all participants funds. Nobody can steal funds, or change contract functions.": "智能合约技术可确保所有参与者资金的完全安全。没有人可以窃取资金或更改合同功能。",
        "Worldwide legal": "全球法律",
        "company with": "与公司",
        "professional team": "专业团队",
        "We are the officially registered company with team of trusted professionals on blockchain market. We are working 24/7 to increase platform popularity and make it truly worldwide.": "我们是在区块链市场上拥有可信赖的专业团队的正式注册公司。我们正在24/7全天候工作，以提高平台的知名度，并使其真正在全球范围内推广。",
        "Investment conditions": "投资条件",
        "the": "的",
        "best": "最好",
        "market offer": "市场报价",
        "Total invested amount (Core)": "总投资额（Core）",
        "Total active participants": "活跃参与者总数",
        "Basic interest rate": "基本利率",
        "+1% every 24 hours (+0.0416% hourly)": "每24小时+ 1％（每小时+ 0.0416％）",
        "CoreAvail smart-contract calculate profit up to every deposit since the date it was made. Every hour you will get": "自创建之日起，CoreAvail智能合约最多可以计算每笔存款的利润。每小时您都会得到",
        "every day": "每天",
        "Personal hold-bonus": "个人持有奖金",
        "+0.1% for every 24 hours without withdraw": "每24小时不收取提现+ 0.1％",
        "Smart-contract calculates hold-bonus from your deposit, or last withdraw date. If you did not request payment, it will charge you an additional bonus. After 24 hours": "智能合约从您的存款或上次提款日期计算保留红利。如果您不要求付款，它将向您收取额外的奖金。24小时后",
        "after 48 hours": "48小时后",
        "after 72 hours": "72小时后",
        "and so on.": "等等。",
        "Contract total amount bonus": "合同总奖金",
        "+0.1% for every 3,000,000 Core on platform balance": "每3,000,000 Core平台余额+ 0.1％",
        "Smart-contract check its current balance and charge an additional": "智能合约检查其当前余额并收取额外费用",
        "up to your earnings for every 3,000,000 Core on balance.": "余额中每3,000,000 Core的收入上限。",
        "CURRENT BONUS": "当前奖金",
        "Current platform balance (Core)": "当前平台余额（Core）",
        "Honest": "诚实",
        "Transparent": "透明",
        "The": "的",
        "Best": "最好",
        "Market Offer": "市场报价",
        "CoreAvail platform smart-contract is published on Core blockchain. Nobody can change its rules or algorithms, even administration. This is provide our participants unconditional confidence in safety of their funds. Anyone can check smart-contract code and be sure that CoreAvail platform is honest.": "CoreAvail平台智能合约发布在Core区块链上。没有人可以更改其规则或算法，甚至管理。这为我们的参与者提供了对其资金安全的无条件信心。任何人都可以检查智能合约代码，并确保CoreAvail平台诚实。",
        "Independent company": "独立公司",
        "HAECHI": "羽枝",
        "Website": "网站",
        "Audition status": "试演状态",
        "Successfully passed": "成功通过",
        "Audit conclusion (PDF)": "审核结论（PDF）",
        "Smart-contract code": "智能合约代码",
        "Reviews and mentionings": "评论和提及",
        "Last deposits": "最后存款",
        "Step #1": "第1步",
        "Make deposit": "存款",
        "Get": "得到",
        "Core (CORE)": "创（Core）",
        "We recommend to use": "我们建议使用",
        "browsers extensions, or": "浏览器扩展，或",
        "mobile apps. You can get Core coins via popular exchangers.": "移动应用。您可以通过流行的兑换器获得Core硬币。",
        "SEND": "发送",
        "Core TO OUR SMART-CONTRACT": "Core到我们的智能合约",
        "Send any amount of Core on smart-contract address and start to get earnings every moment. Minimal deposit amount is": "在智能合约地址上发送任意数量的Core，并开始获取收益。最低存款金额为",
        "No maximal amount.": "没有最大数量。",
        "Step #2": "第2步",
        "Get earnings": "获得收益",
        "Get your":"拿你的",
        "Core every moment": "每时每刻",
        "You can check all your wallet information, including your deposits, earnings, withdraw and referral statistics in real time.": "您可以实时查看所有钱包信息，包括您的存款，收入，提款和推荐统计信息。",
        "Additional earnings with referral program": "推荐计划可增加收入",
        "3 levels of affiliate rewards": "3级会员奖励",
        "Referral rewards comes instantly on your wallet balance and can be withdrawn any time along with earnings.": "推荐奖金立即出现在您的钱包余额中，可以随时随收入一起提取。",
        "Matching Commission of":"配套委员会",
        "on Partners Daily Income every time they make a withdrawal":"合伙人每次提款时的每日收入",
        "1 new level is activated for each direct partner, maximum 10 levels.":"每个直接伙伴将激活一个新级别，最多10个级别。",
        "Your Wallet Statistics": "您的钱包统计",
        "Step #3": "第三步",
        "Request withdraw": "要求提款",
        "Request": "请求",
        "Core Withdraw": "Core提取",
        "Your payout will come": "您的付款将到",
        "instantly": "即刻",
        "Request withdraw from the": "要求退出",
        "same wallet": "同一个钱包",
        "you deposited": "你存了",
        "All your wallet deposits and referral earnings will be withdrawn with single transaction per 1 request": "您的所有钱包存款和推荐收入将按每1个请求的单笔交易提取",
        "Payment of deposit": "支付定金",
        "Deposit Bonus is paid daily throughout the term": "在整个学期中每天支付存款奖金",
        "Body of the deposit is paid at the end of the term": "保证金在期末支付",
        "The Bonus and the deposit itself are transferred to your internal balance": "奖金和存款本身转移到您的内部余额中",
        "Withdraw money from the balance to your wallet": "从余额中取出钱到您的钱包",
        "You can make a new deposit and get more profit!": "您可以进行新的存款并获得更多的利润！",
        "Start Investment Now": "立即开始投资",
        "Investment Conditions": "投资条件",
        "Basic Interest Rate": "基本利率",
        "Every 24 Hours": "每24小时",
        "Hourly": "每小时一次",
        "Personal hold-Bonus": "个人持有奖金",
        "For Every 24 Hours Without Withdraw": "每24小时不取款",
        "Contract Total Amount Bonus": "合同总奖金",
        "For Every": "对于每个",
        "Core  On Plateform Balance": "平台秤上的Core",
        "Testimonial": "感言",
        "Join Us": "加入我们",
        "Understand Golff, communicate with the team, and join the communities to build a decentralized financial world together": "了解Golff，与团队沟通并加入社区，共同构建去中心化的金融世界",
        "Our Representative": "我们的代表",
        "Copyrights": "版权",
        "Blockchain investment platform": "区块链投资平台",
        "All Rights Reserved": "版权所有",
        "Smart-contract address": "智能合约地址",
        "Powered by": "供电",
        "Core blockchain": "Core区块链",
        "Make New Deposit": "进行新的存款",
        "Connect with your wallet app...": "连接您的钱包应用...",
        "Connect with your": "与您的联系",
        "browser extension, or": "浏览器扩展，或",
        "mobile app. After connection you will get opportunity to make deposit (reload this page).": "移动应用。连接后，您将有机会进行存款（重新加载此页面）。",
        "Please, contact our support team if you cant connect.": "如果您无法连接，请联系我们的支持团队。",
        "Your upline partner wallet": "您的上线合作伙伴钱包",
        "No upline": "没有上线",
        "Specify deposit": "指定存款",
        "Core amount here": "Core金额在这里",
        "Please enter amount": "请输入金额",
        "Minimum amount is 10 Core": "最低金额为10 Core",
        "IMPORTANT!": "重要！",
        "Do not forget about blockchain fee! You should have 2-5 Core more on your wallet, or your transaction will get out of energy status!": "不要忘记区块链费用！您的钱包中应该还有2-5 Core，否则您的交易将失去能量状态！",
        "Minimal deposit amount": "最低存款额",
        "(no maximal limits)": "（没有最大限制）",
        "We are working only with": "我们只与",
        "Core (CORE) cryptocurrency": "Core（Core）加密货币",
        "Your deposit will be activated after": "您的存款将在之后激活",
        "1 confirmation": "1个确认",
        "in blockchain": "在区块链中",
        "Earnings comes every moment on your wallet balance, you can withdraw it any time you want": "收益每时每刻都在您的钱包余额中，您可以随时提取",
        "Your": "你的",
        "Core wallet address": "Core钱包地址",
        "Your referral link": "您的推荐链接",
        "Share this link with your partners to get referral rewards": "与您的合作伙伴共享此链接以获得推荐奖励",
        "You should have active deposit to unlock affiliate bonuses! You will get no rewards without active deposit.": "您应该有充值存款才能解锁会员奖金！没有有效存款，您将不会获得任何奖励。",
        "Your current daily profit": "您当前的每日利润",
        "Basic profit": "基本利润",
        "Hold-bonus": "持有奖金",
        "Contract bonus": "合同奖金",
        "Total invested": "总投资",
        "Total earned": "总收入",
        "Match bonus": "比赛奖金",
        "Total withdrawn": "提取总额",
        "Referral rewards": "推荐奖励",
        "Number of deposits": "存款数量",
        "Last deposit date": "最后存款日期",
        "no deposits": "没有存款",
        "Available withdraw balance": "可用提款余额",
        "Withdraw": "收回",
        "1st Level": "1级",
        "2nd Level": "2级",
        "3rd Level": "第三级",
        "Your referral link": "您的推荐链接",
        "Copy referral link": "复制引荐链接",
        "Contest": "比赛",
        "COMING SOON!": "快来了！",
      }
    },
    es: {
      translations: {
        "Reliable Investments With": "Inversiones confiables con",
        "Promo": "Promoción",
        "Representative": "",
        "About us": "Sobre nosotros",
        "Investment": "Inversión",
        "Contest": "Concurso",
        "Make Deposit": "Realizar el depósito",
        "Wallet Statistic": "Estadística de billetera",

        "ENGLISH": "INGLÉS",
        "PERSIAN": "PERSA",
        "RUSSIAN": "RUSO",
        "CHINESE": "CHINO",
        "SPANISH": "ESPAÑOL",
        "ARABIC": "ARÁBICA",
        "TURKISH": "TURCO",
        "Get +200% Up To Your Deposit": "Obtenga + 200% hasta su depósito",
        "Basic Interest": "Interés básico",
        "Every": "Cada",
        "Hours": "Horas",
        "Make Investment Now": "Haga una inversión ahora",
        "Blockchain decentralized and anonymous platform": "Plataforma blockchain descentralizada y anónima",
        "Totally secure income based on": "Ingresos totalmente seguros basados ​​en",
        "Core smart-contract": "Contrato inteligente de Core",
        "Smart-contract verified and audited by independent company": "Contrato inteligente verificado y auditado por una empresa independiente",
        "Working only with":"Trabajando solo con",
        "Core (CORE)":"Core (CORE)",
        "network":"red",
        "Minimal deposit":"Depósito mínimo",
        "10 Core":"10 Core",
        "Total income":"Ingresos totales",
        "200%":"200%",
        "(deposit included)":"(deposito incluido)",
        "Earnings":"Ganancias",
        "every moment":"cada momento",
        ", withdraw any time":", retirar en cualquier momento",
        "Contract": "Contrato",
        "Interest Calculator": "Calculadora de intereses",
        "Hourly": "Cada hora",
        "Payments": "Pagos",
        "You’ll Deposit": "Depositarás",
        "Current profit": "Beneficio actual",
        "Deposit Amount": "Cantidad del depósito",
        "Currently hourly profit": "Beneficio por hora actual",
        "Maximum profit": "Beneficio máximo",
        "ROI Reached in": "ROI alcanzado en",
        "days": "dias",
        "Make Investment": "Hacer Inversión",
        "CoreAvail is a decentralized, community-based, 100% fair, and smart contract-verified investment platform. It’s also the safest investment platform available.": "CoreAvail es una plataforma de inversión con verificación de contrato inteligente, 100% justa y descentralizada, basada en la comunidad. También es la plataforma de inversión más segura disponible.",
        "We Will": "Lo haremos",
        "Double": "Doble",
        "Your Funds": "Sus fondos",
        "Unlimited Earnings": "Ganancias ilimitadas",
        "With Always": "Con siempre",
        "Growing Rates": "Tasas crecientes",
        "We Provide investment conditions with growing percentage, depending on basic interest rate, smart-contract total balance bonus and personal": "Brindamos condiciones de inversión con porcentaje creciente, dependiendo de la tasa de interés básica, bonificación de saldo total de contrato inteligente y personal",
        "hold-bonus": "bono de espera",
        "Maximal available profit": "Beneficio máximo disponible",
        "+200% per every deposit": "+ 200% por cada depósito",
        "Reliability of Core": "Fiabilidad de Core",
        "smart-contract.": "contrato inteligente.",
        "Scam is impossible!": "¡La estafa es imposible!",
        "Smart-contract technology ensures full safety of all participants funds. Nobody can steal funds, or change contract functions.": "La tecnología de contrato inteligente garantiza la total seguridad de los fondos de todos los participantes. Nadie puede robar fondos ni cambiar funciones contractuales.",
        "Worldwide legal": "Legal en todo el mundo",
        "company with": "compañía con",
        "professional team": "equipo profesional",
        "We are the officially registered company with team of trusted professionals on blockchain market. We are working 24/7 to increase platform popularity and make it truly worldwide.": "Somos la empresa registrada oficialmente con un equipo de profesionales de confianza en el mercado blockchain. Trabajamos las 24 horas del día, los 7 días de la semana para aumentar la popularidad de la plataforma y hacerla verdaderamente mundial.",
        "Investment conditions": "Condiciones de inversión",
        "the": "los",
        "best": "mejor",
        "market offer": "oferta de mercado",
        "Total invested amount (Core)": "Monto total invertido (Core)",
        "Total active participants": "Total de participantes activos",
        "Basic interest rate": "Tasa de interés básica",
        "+1% every 24 hours (+0.0416% hourly)": "+ 1% cada 24 horas (+ 0.0416% por hora)",
        "CoreAvail smart-contract calculate profit up to every deposit since the date it was made. Every hour you will get": "El contrato inteligente de CoreAvail calcula las ganancias hasta cada depósito desde la fecha en que se realizó. Cada hora obtendrás",
        "every day": "cada día",
        "Personal hold-bonus": "Bono de retención personal",
        "+0.1% for every 24 hours without withdraw": "+ 0.1% por cada 24 horas sin retirar",
        "Smart-contract calculates hold-bonus from your deposit, or last withdraw date. If you did not request payment, it will charge you an additional bonus. After 24 hours": "Smart-contract calcula el bono de retención a partir de su depósito o la última fecha de retiro. Si no solicitó el pago, se le cobrará una bonificación adicional. Después de 24 horas",
        "after 48 hours": "después de 48 horas",
        "after 72 hours": "después de 72 horas",
        "and so on.": "y así.",
        "Contract total amount bonus": "Bonificación por monto total del contrato",
        "+0.1% for every 3,000,000 Core on platform balance": "+ 0.1% por cada 3,000,000 Core en el saldo de la plataforma",
        "Smart-contract check its current balance and charge an additional": "Smart-contract verifica su saldo actual y cobra un cargo adicional",
        "up to your earnings for every 3,000,000 Core on balance.": "hasta sus ganancias por cada 3,000,000 Core en saldo.",
        "CURRENT BONUS": "BONO ACTUAL",
        "Current platform balance (Core)": "Balance de plataforma actual (Core)",
        "Honest": "Honesto",
        "Transparent": "Transparente",
        "The": "los",
        "Best": "Mejor",
        "Market Offer": "Oferta de mercado",
        "CoreAvail platform smart-contract is published on Core blockchain. Nobody can change its rules or algorithms, even administration. This is provide our participants unconditional confidence in safety of their funds. Anyone can check smart-contract code and be sure that CoreAvail platform is honest.": "El contrato inteligente de la plataforma CoreAvail se publica en la cadena de bloques Core. Nadie puede cambiar sus reglas o algoritmos, ni siquiera la administración. Esto proporciona a nuestros participantes una confianza incondicional en la seguridad de sus fondos. Cualquiera puede verificar el código de contrato inteligente y asegurarse de que la plataforma CoreAvail sea honesta.",
        "Independent company": "Empresa independiente",
        "HAECHI": "HAECHI",
        "Website": "Sitio web",
        "Audition status": "Estado de la audición",
        "Successfully passed": "Superado exitosamente",
        "Audit conclusion (PDF)": "Conclusión de la auditoría (PDF)",
        "Smart-contract code": "Código de contrato inteligente",
        "Reviews and mentionings": "Reseñas y menciones",
        "Last deposits": "Últimos depósitos",
        "Step #1": "Paso 1",
        "Make deposit": "Realizar el depósito",
        "Get": "Obtener",
        "Core (CORE)": "Core (CORE)",
        "We recommend to use": "Recomendamos usar",
        "browsers extensions, or": "extensiones de navegadores, o",
        "mobile apps. You can get Core coins via popular exchangers.": "aplicaciones móviles. Puede obtener monedas Core a través de intercambiadores populares.",
        "SEND": "ENVIAR",
        "Core TO OUR SMART-CONTRACT": "Core A NUESTRO CONTRATO INTELIGENTE",
        "Send any amount of Core on smart-contract address and start to get earnings every moment. Minimal deposit amount is": "Envíe cualquier cantidad de Core a una dirección de contrato inteligente y comience a obtener ganancias en todo momento. La cantidad mínima de depósito es",
        "No maximal amount.": "Sin monto máximo.",
        "Step #2": "Paso 2",
        "Get earnings": "Obtener ganancias",
        "Get your":"Conseguir su",
        "Core every moment": "Core en cada momento",
        "You can check all your wallet information, including your deposits, earnings, withdraw and referral statistics in real time.": "Puede verificar toda la información de su billetera, incluidos sus depósitos, ganancias, retiros y estadísticas de referencias en tiempo real.",
        "Additional earnings with referral program": "Ingresos adicionales con el programa de referencia",
        "3 levels of affiliate rewards": "3 niveles de recompensas para afiliados",
        "Referral rewards comes instantly on your wallet balance and can be withdrawn any time along with earnings.": "Las recompensas por recomendación vienen instantáneamente en el saldo de su billetera y se pueden retirar en cualquier momento junto con las ganancias.",
        "Matching Commission of":"Comisión de emparejamiento de",
        "on Partners Daily Income every time they make a withdrawal":"en los ingresos diarios de los socios cada vez que hacen un retiro",
        "1 new level is activated for each direct partner, maximum 10 levels.":"Se activa 1 nuevo nivel para cada socio directo, máximo 10 niveles.",
        "Your Wallet Statistics": "Estadísticas de su billetera",
        "Step #3": "Paso 3",
        "Request withdraw": "Solicitar retiro",
        "Request": "Solicitud",
        "Core Withdraw": "Retirar Core",
        "Your payout will come": "Tu pago vendrá",
        "instantly": "instantáneamente",
        "Request withdraw from the": "Solicitar retirarse del",
        "same wallet": "misma billetera",
        "you deposited": "tú depositaste",
        "All your wallet deposits and referral earnings will be withdrawn with single transaction per 1 request": "Todos los depósitos de su billetera y las ganancias por referencias se retirarán con una sola transacción por 1 solicitud",
        "Payment of deposit": "Pago de depósito",
        "Deposit Bonus is paid daily throughout the term": "La bonificación por depósito se paga diariamente durante todo el plazo.",
        "Body of the deposit is paid at the end of the term": "El cuerpo del depósito se paga al final del plazo.",
        "The Bonus and the deposit itself are transferred to your internal balance": "El bono y el depósito en sí se transfieren a su saldo interno",
        "Withdraw money from the balance to your wallet": "Retirar dinero del saldo a su billetera",
        "You can make a new deposit and get more profit!": "¡Puede hacer un nuevo depósito y obtener más ganancias!",
        "Start Investment Now": "Empiece a invertir ahora",
        "Investment Conditions": "Condiciones de inversión",
        "Basic Interest Rate": "Tasa de interés básica",
        "Every 24 Hours": "Cada 24 horas",
        "Hourly": "Cada hora",
        "Personal hold-Bonus": "Bono de retención personal",
        "For Every 24 Hours Without Withdraw": "Por cada 24 horas sin retirar",
        "Contract Total Amount Bonus": "Bonificación del monto total del contrato",
        "For Every": "Para cada",
        "Core  On Plateform Balance": "Core en equilibrio sobre plataforma",
        "Testimonial": "Testimonial",
        "Join Us": "Únete a nosotros",
        "Understand Golff, communicate with the team, and join the communities to build a decentralized financial world together": "Comprenda a Golff, comuníquese con el equipo y únase a las comunidades para construir juntos un mundo financiero descentralizado",
        "Our Representative": "Nuestro Representante",
        "Copyrights": "Derechos de autor",
        "Blockchain investment platform": "Plataforma de inversión blockchain",
        "All Rights Reserved": "Todos los derechos reservados",
        "Smart-contract address": "Dirección de contrato inteligente",
        "Powered by": "Energizado por",
        "Core blockchain": "Core blockchain",
        "Make New Deposit": "Hacer un nuevo depósito",
        "Connect with your wallet app...": "Conéctese con la aplicación de su billetera ...",
        "Connect with your": "Conéctate con tu",
        "browser extension, or": "extensión del navegador, o",
        "mobile app. After connection you will get opportunity to make deposit (reload this page).": "aplicación movil. Después de la conexión, tendrá la oportunidad de realizar un depósito (vuelva a cargar esta página).",
        "Please, contact our support team if you cant connect.": "Por favor, comuníquese con nuestro equipo de soporte si no puede conectarse.",
        "Your upline partner wallet": "La billetera de su socio de línea ascendente",
        "No upline": "Sin línea ascendente",
        "Specify deposit": "Especificar depósito",
        "Core amount here": "Cantidad de Core aquí",
        "Please enter amount": "Por favor ingrese la cantidad",
        "Minimum amount is 10 Core": "La cantidad mínima es 10 Core",
        "IMPORTANT!": "¡IMPORTANTE!",
        "Do not forget about blockchain fee! You should have 2-5 Core more on your wallet, or your transaction will get out of energy status!": "¡No te olvides de la tarifa de blockchain! ¡Debería tener 2-5 Core más en su billetera, o su transacción saldrá del estado de energía!",
        "Minimal deposit amount": "Cantidad mínima de depósito",
        "(no maximal limits)": "(sin límites máximos)",
        "We are working only with": "Estamos trabajando solo con",
        "Core (CORE) cryptocurrency": "Criptomoneda Core (CORE)",
        "Your deposit will be activated after": "Su depósito se activará después",
        "1 confirmation": "1 confirmación",
        "in blockchain": "en blockchain",
        "Earnings comes every moment on your wallet balance, you can withdraw it any time you want": "Las ganancias vienen en cada momento en el saldo de su billetera, puede retirarlas cuando lo desee",
        "Your": "Tu",
        "Core wallet address": "Dirección de billetera Core",
        "Your referral link": "Tu enlace de referencia",
        "Share this link with your partners to get referral rewards": "Comparta este enlace con sus socios para obtener recompensas por referencias",
        "You should have active deposit to unlock affiliate bonuses! You will get no rewards without active deposit.": "¡Debería tener un depósito activo para desbloquear los bonos de afiliados! No obtendrá recompensas sin un depósito activo.",
        "Your current daily profit": "Tu beneficio diario actual",
        "Basic profit": "Beneficio básico",
        "Hold-bonus": "Bono de retención",
        "Contract bonus": "Bono de contrato",
        "Total invested": "Total invertido",
        "Total earned": "Total de Ganancias",
        "Match bonus": "Bono de partido",
        "Total withdrawn": "Total retirado",
        "Referral rewards": "Recompensas por referencias",
        "Number of deposits": "Cantidad de depósitos",
        "Last deposit date": "Última fecha de depósito",
        "no deposits": "sin depósitos",
        "Available withdraw balance": "Saldo de retiro disponible",
        "Withdraw": "Retirar",
        "1st Level": "1er nivel",
        "2nd Level": "2do nivel",
        "3rd Level": "3er nivel",
        "Your referral link": "Tu enlace de referencia",
        "Copy referral link": "Copiar enlace de referencia",
        "Contest": "Concurso",
        "COMING SOON!": "¡PRÓXIMAMENTE!",
      }
    },
    ar: {
      translations: {
        "Reliable Investments With": "استثمارات موثوقة مع",
        "Promo": "الترويجي",
        "Representative": "وكيل",
        "About us": "معلومات عنا",
        "Investment": "الاستثمار",
        "Contest": "مسابقة",
        "Make Deposit": "جعل الودائع",
        "Wallet Statistic": "إحصائية المحفظة",

        "ENGLISH": "الإنجليزية",
        "PERSIAN": "اللغة الفارسية",
        "RUSSIAN": "الروسية",
        "CHINESE": "صينى",
        "SPANISH": "الأسبانية",
        "ARABIC": "عربى",
        "TURKISH": "اللغة التركية",
        "Get +200% Up To Your Deposit": "احصل على + 200٪ يصل إلى الإيداع الخاص بك",
        "Basic Interest": "الفائدة الأساسية",
        "Every": "كل",
        "Hours": "ساعات",
        "Make Investment Now": "استثمر الآن",
        "Blockchain decentralized and anonymous platform": "منصة Blockchain اللامركزية والمجهولة",
        "Totally secure income based on": "دخل آمن تمامًا على أساس",
        "Core smart-contract": "عقد ذكي Core",
        "Smart-contract verified and audited by independent company": "العقد الذكي مصدق ومدقق من قبل شركة مستقلة",
        "Working only with":"تعمل فقط مع",
        "Core (CORE)":"ترون (Core)",
        "network":"شبكة الاتصال",
        "Minimal deposit":"إيداع أدنى",
        "10 Core":"10 Core",
        "Total income":"إجمالي الدخل",
        "200%":"200٪",
        "(deposit included)":"(الإيداع متضمن)",
        "Earnings":"أرباح",
        "every moment":"كل لحظة",
        ", withdraw any time":"، اسحب في أي وقت",
        "Contract": "عقد",
        "Interest Calculator": "حاسبة الفائدة",
        "Hourly": "ساعيا",
        "Payments": "المدفوعات",
        "You’ll Deposit": "سوف تقوم بالإيداع",
        "Current profit": "الربح الحالي",
        "Deposit Amount": "قيمة الايداع",
        "Currently hourly profit": "الربح بالساعة حاليًا",
        "Maximum profit": "أقصى ربح",
        "ROI Reached in": "تم الوصول إلى عائد الاستثمار",
        "days": "أيام",
        "Make Investment": "قم بالاستثمار",
        "CoreAvail is a decentralized, community-based, 100% fair, and smart contract-verified investment platform. It’s also the safest investment platform available.": "CoreAvail عبارة عن منصة استثمار لا مركزية ومجتمعية وعادلة 100٪ وذكية تم التحقق من عقدها. إنها أيضًا منصة الاستثمار الأكثر أمانًا المتاحة.",
        "We Will": "سنقوم",
        "Double": "مزدوج",
        "Your Funds": "أموالك",
        "Unlimited Earnings": "أرباح غير محدودة",
        "With Always": "مع دائما",
        "Growing Rates": "تزايد معدلات",
        "We Provide investment conditions with growing percentage, depending on basic interest rate, smart-contract total balance bonus and personal": "نحن نوفر شروطًا استثمارية بنسبة متزايدة ، اعتمادًا على سعر الفائدة الأساسي ، ومكافأة الرصيد الإجمالي للعقد الذكي والشخصي",
        "hold-bonus": "عقد مكافأة",
        "Maximal available profit": "أقصى ربح متاح",
        "+200% per every deposit": "+ 200٪ لكل إيداع",
        "Reliability of Core": "موثوقية Core",
        "smart-contract.": "عقد ذكي.",
        "Scam is impossible!": "الغش مستحيل!",
        "Smart-contract technology ensures full safety of all participants funds. Nobody can steal funds, or change contract functions.": "تضمن تقنية العقد الذكي السلامة الكاملة لجميع أموال المشاركين. لا أحد يستطيع سرقة الأموال ، أو تغيير وظائف العقد.",
        "Worldwide legal": "عالمي قانوني",
        "company with": "شركة مع",
        "professional team": "فريق فني",
        "We are the officially registered company with team of trusted professionals on blockchain market. We are working 24/7 to increase platform popularity and make it truly worldwide.": "نحن شركة مسجلة رسميًا مع فريق من المهنيين الموثوق بهم في سوق blockchain. نحن نعمل على مدار الساعة طوال أيام الأسبوع لزيادة شعبية المنصة وجعلها عالمية حقًا.",
        "Investment conditions": "شروط الاستثمار",
        "the": "ال",
        "best": "الأفضل",
        "market offer": "عرض السوق",
        "Total invested amount (Core)": "إجمالي المبلغ المستثمر (Core)",
        "Total active participants": "مجموع المشاركين النشطين",
        "Basic interest rate": "سعر الفائدة الأساسي",
        "+1% every 24 hours (+0.0416% hourly)": "+ 1٪ كل 24 ساعة (+ 0.0416٪ كل ساعة)",
        "CoreAvail smart-contract calculate profit up to every deposit since the date it was made. Every hour you will get": "عقد CoreAvail الذكي يحسب الربح حتى كل إيداع منذ تاريخ إجرائه. كل ساعة ستحصل عليها",
        "every day": "كل يوم",
        "Personal hold-bonus": "مكافأة الحجز الشخصي",
        "+0.1% for every 24 hours without withdraw": "+ 0.1٪ لكل 24 ساعة بدون سحب",
        "Smart-contract calculates hold-bonus from your deposit, or last withdraw date. If you did not request payment, it will charge you an additional bonus. After 24 hours": "يحسب العقد الذكي مكافأة الانتظار من إيداعك أو تاريخ السحب الأخير. إذا لم تطلب السداد ، فستخصم منك مكافأة إضافية. بعد 24 ساعة",
        "after 48 hours": "بعد 48 ساعة",
        "after 72 hours": "بعد 72 ساعة",
        "and so on.": "وهلم جرا.",
        "Contract total amount bonus": "مكافأة إجمالي العقد",
        "+0.1% for every 3,000,000 Core on platform balance": "+ 0.1٪ لكل 3،000،000 Core على رصيد المنصة",
        "Smart-contract check its current balance and charge an additional": "العقد الذكي تحقق من رصيده الحالي وشحن مبلغًا إضافيًا",
        "up to your earnings for every 3,000,000 Core on balance.": "حتى أرباحك لكل 3،000،000 Core رصيد.",
        "CURRENT BONUS": "المكافأة الحالية",
        "Current platform balance (Core)": "رصيد المنصة الحالي (Core)",
        "Honest": "صادق",
        "Transparent": "شفاف",
        "The": "ال",
        "Best": "الأفضل",
        "Market Offer": "عرض السوق",
        "CoreAvail platform smart-contract is published on Core blockchain. Nobody can change its rules or algorithms, even administration. This is provide our participants unconditional confidence in safety of their funds. Anyone can check smart-contract code and be sure that CoreAvail platform is honest.": "تم نشر العقد الذكي لمنصة CoreAvail على Core blockchain. لا أحد يستطيع تغيير قواعده أو خوارزمياته ، حتى الإدارة. هذا هو تزويد المشاركين لدينا ثقة غير مشروطة في سلامة أموالهم. يمكن لأي شخص التحقق من رمز العقد الذكي والتأكد من صدق منصة CoreAvail.",
        "Independent company": "شركة مستقلة",
        "HAECHI": "هاشي",
        "Website": "موقع الكتروني",
        "Audition status": "حالة الاختبار",
        "Successfully passed": "اجتاز بنجاح",
        "Audit conclusion (PDF)": "استنتاج المراجعة (PDF)",
        "Smart-contract code": "رمز العقد الذكي",
        "Reviews and mentionings": "المراجعات والإشارات",
        "Last deposits": "الودائع الأخيرة",
        "Step #1": "الخطوة 1",
        "Make deposit": "جعل الودائع",
        "Get": "احصل على",
        "Core (CORE)": "ترون (Core)",
        "We recommend to use": "نوصي باستخدام",
        "browsers extensions, or": "المتصفحات ، أو",
        "mobile apps. You can get Core coins via popular exchangers.": "تطبيقات الموبايل. يمكنك الحصول على عملات Core عبر المبادلات الشهيرة.",
        "SEND": "إرسال",
        "Core TO OUR SMART-CONTRACT": "Core لعقدنا الذكي",
        "Send any amount of Core on smart-contract address and start to get earnings every moment. Minimal deposit amount is": "أرسل أي مبلغ من Core على عنوان العقد الذكي وابدأ في جني الأرباح في كل لحظة. الحد الأدنى لمبلغ الإيداع هو",
        "No maximal amount.": "لا يوجد حد أقصى للمبلغ.",
        "Step #2": "الخطوة 2",
        "Get earnings": "احصل على أرباح",
        "Get your":"خذ الخاصة بك",
        "Core every moment": "Core كل لحظة",
        "You can check all your wallet information, including your deposits, earnings, withdraw and referral statistics in real time.": "يمكنك التحقق من جميع معلومات محفظتك ، بما في ذلك الودائع والأرباح والسحب وإحصائيات الإحالة في الوقت الفعلي.",
        "Additional earnings with referral program": "أرباح إضافية مع برنامج الإحالة",
        "3 levels of affiliate rewards": "3 مستويات من المكافآت التابعة",
        "Referral rewards comes instantly on your wallet balance and can be withdrawn any time along with earnings.": "تأتي مكافآت الإحالة على الفور في رصيد محفظتك ويمكن سحبها في أي وقت مع الأرباح.",
        "Matching Commission of":"مطابقة عمولة",
        "on Partners Daily Income every time they make a withdrawal":"على الدخل اليومي للشركاء في كل مرة يقومون فيها بالسحب",
        "1 new level is activated for each direct partner, maximum 10 levels.":"يتم تنشيط مستوى جديد واحد لكل شريك مباشر ، بحد أقصى 10 مستويات.",
        "Your Wallet Statistics": "إحصائيات محفظتك",
        "Step #3": "الخطوه 3",
        "Request withdraw": "طلب سحب",
        "Request": "طلب",
        "Core Withdraw": "سحب Core",
        "Your payout will come": "سيأتي العائد الخاص بك",
        "instantly": "فورا",
        "Request withdraw from the": "طلب الانسحاب من",
        "same wallet": "نفس المحفظة",
        "you deposited": "قمت بإيداعها",
        "All your wallet deposits and referral earnings will be withdrawn with single transaction per 1 request": "سيتم سحب جميع ودائع محفظتك وأرباح الإحالة بمعاملة واحدة لكل طلب واحد",
        "Payment of deposit": "دفع الوديعة",
        "Deposit Bonus is paid daily throughout the term": "يتم دفع مكافأة الإيداع يوميًا طوال المدة",
        "Body of the deposit is paid at the end of the term": "يتم دفع نص الإيداع في نهاية المدة",
        "The Bonus and the deposit itself are transferred to your internal balance": "يتم تحويل المكافأة والإيداع نفسه إلى رصيدك الداخلي",
        "Withdraw money from the balance to your wallet": "سحب الأموال من الرصيد إلى محفظتك",
        "You can make a new deposit and get more profit!": "يمكنك عمل إيداع جديد والحصول على المزيد من الأرباح!",
        "Start Investment Now": "ابدأ الاستثمار الآن",
        "Investment Conditions": "شروط الاستثمار",
        "Basic Interest Rate": "سعر الفائدة الأساسي",
        "Every 24 Hours": "كل 24 ساعة",
        "Hourly": "ساعيا",
        "Personal hold-Bonus": "مكافأة الحجز الشخصي",
        "For Every 24 Hours Without Withdraw": "لكل 24 ساعة بدون سحب",
        "Contract Total Amount Bonus": "مكافأة مبلغ العقد الإجمالي",
        "For Every": "لكل",
        "Core  On Plateform Balance": "Core على لوحة الميزان",
        "Testimonial": "شهادة",
        "Join Us": "انضم إلينا",
        "Understand Golff, communicate with the team, and join the communities to build a decentralized financial world together": "فهم Golff ، والتواصل مع الفريق ، والانضمام إلى المجتمعات لبناء عالم مالي لامركزي معًا",
        "Our Representative": "ممثلنا",
        "Copyrights": "حقوق التأليف والنشر",
        "Blockchain investment platform": "منصة استثمار Blockchain",
        "All Rights Reserved": "كل الحقوق محفوظة",
        "Smart-contract address": "عنوان العقد الذكي",
        "Powered by": "مشغل بواسطة",
        "Core blockchain": "بلوكشين ترون",
        "Make New Deposit": "قم بعمل إيداع جديد",
        "Connect with your wallet app...": "تواصل مع تطبيق محفظتك ...",
        "Connect with your": "تواصل مع",
        "browser extension, or": "امتداد المتصفح ، أو",
        "mobile app. After connection you will get opportunity to make deposit (reload this page).": "تطبيق جوال. بعد الاتصال ستحصل على فرصة للإيداع (أعد تحميل هذه الصفحة).",
        "Please, contact our support team if you cant connect.": "من فضلك ، اتصل بفريق الدعم الخاص بنا إذا لم تتمكن من الاتصال.",
        "Your upline partner wallet": "المحفظة الخاصة بك شريك upline",
        "No upline": "لا upline",
        "Specify deposit": "حدد الإيداع",
        "Core amount here": "مبلغ Core هنا",
        "Please enter amount": "الرجاء إدخال المبلغ",
        "Minimum amount is 10 Core": "الحد الأدنى 100 ليرة تركية",
        "IMPORTANT!": "مهم!",
        "Do not forget about blockchain fee! You should have 2-5 Core more on your wallet, or your transaction will get out of energy status!": "لا تنسى رسوم blockchain! يجب أن يكون لديك 2-5 Core إضافية في محفظتك ، أو ستخرج معاملتك من حالة الطاقة!",
        "Minimal deposit amount": "الحد الأدنى من مبلغ الإيداع",
        "(no maximal limits)": "(بلا ​​حدود قصوى)",
        "We are working only with": "نحن نعمل فقط مع",
        "Core (CORE) cryptocurrency": "العملة المشفرة Core (CORE)",
        "Your deposit will be activated after": "سيتم تفعيل إيداعك بعد",
        "1 confirmation": "1 تأكيد",
        "in blockchain": "في blockchain",
        "Earnings comes every moment on your wallet balance, you can withdraw it any time you want": "تأتي الأرباح في كل لحظة في رصيد محفظتك ، يمكنك سحبها في أي وقت تريده",
        "Your": "الخاص بك",
        "Core wallet address": "عنوان محفظة Core",
        "Your referral link": "رابط الإحالة الخاص بك",
        "Share this link with your partners to get referral rewards": "شارك هذا الرابط مع شركائك للحصول على مكافآت الإحالة",
        "You should have active deposit to unlock affiliate bonuses! You will get no rewards without active deposit.": "يجب أن يكون لديك إيداع نشط لإلغاء تأمين المكافآت التابعة! لن تحصل على مكافآت بدون إيداع نشط.",
        "Your current daily profit": "ربحك اليومي الحالي",
        "Basic profit": "الربح الأساسي",
        "Hold-bonus": "عقد مكافأة",
        "Contract bonus": "مكافأة العقد",
        "Total invested": "إجمالي المستثمر",
        "Total earned": "إجمالي المكتسبة",
        "Match bonus": "مكافأة المباراة",
        "Total withdrawn": "إجمالي المسحوب",
        "Referral rewards": "مكافآت الإحالة",
        "Number of deposits": "عدد الودائع",
        "Last deposit date": "تاريخ آخر إيداع",
        "no deposits": "لا ودائع",
        "Available withdraw balance": "الرصيد المتاح للسحب",
        "Withdraw": "انسحب",
        "1st Level": "المستوى الأول",
        "2nd Level": "المستوى الثاني",
        "3rd Level": "المستوى الثالث",
        "Your referral link": "رابط الإحالة الخاص بك",
        "Copy referral link": "انسخ رابط الإحالة",
        "Contest": "مسابقة",
        "COMING SOON!": "قريبا!",
      }
    },
    tr: {
      translations: {
        "Reliable Investments With": "Güvenilir Yatırımlar",
        "Promo": "Promosyon",
        "Representative": "وكيل",
        "About us": "Hakkımızda",
        "Investment": "Yatırım",
        "Contest": "Yarışma",
        "Make Deposit": "Mevduat yapmak",
        "Wallet Statistic": "Cüzdan İstatistiği",

        "ENGLISH": "İNGİLİZCE",
        "PERSIAN": "FARSÇA",
        "RUSSIAN": "RUSÇA",
        "CHINESE": "ÇİNCE",
        "SPANISH": "İSPANYOL",
        "ARABIC": "ARAPÇA",
        "TURKISH": "TÜRK",
        "Get +200% Up To Your Deposit": "Depozitonuza Kadar +% 200 Alın",
        "Basic Interest": "Temel Faiz",
        "Every": "Her",
        "Hours": "Saatler",
        "Make Investment Now": "Şimdi Yatırım Yapın",
        "Blockchain decentralized and anonymous platform": "Blockchain merkezi olmayan ve anonim platform",
        "Totally secure income based on": "Temelinde tamamen güvenli gelir",
        "Core smart-contract": "Core akıllı sözleşme",
        "Smart-contract verified and audited by independent company": "Akıllı sözleşme bağımsız bir şirket tarafından doğrulanmış ve denetlenmiştir",
        "Working only with":"Sadece ile çalışmak",
        "Core (CORE)":"Core (CORE)",
        "network":"ağ",
        "Minimal deposit":"Minimum depozito",
        "10 Core":"10 Core",
        "Total income":"Toplam gelir",
        "200%":"% 200",
        "(deposit included)":"(depozito dahil)",
        "Earnings":"Kazanç",
        "every moment":"Her an",
        ", withdraw any time":", istediğin zaman geri çek",
        "Contract": "Sözleşme",
        "Interest Calculator": "Faiz Hesaplayıcı",
        "Hourly": "Saatlik",
        "Payments": "Ödemeler",
        "You’ll Deposit": "Yatıracaksın",
        "Current profit": "Mevcut kar",
        "Deposit Amount": "Para Yatırma Tutarı",
        "Currently hourly profit": "Şu anda saatlik kar",
        "Maximum profit": "Maksimum kar",
        "ROI Reached in": "YG'ye ulaşıldı",
        "days": "günler",
        "Make Investment": "Yatırım Yapın",
        "CoreAvail is a decentralized, community-based, 100% fair, and smart contract-verified investment platform. It’s also the safest investment platform available.": "CoreAvail, merkezi olmayan, topluluk tabanlı,% 100 adil ve akıllı sözleşme onaylı bir yatırım platformudur. Aynı zamanda mevcut en güvenli yatırım platformudur.",
        "We Will": "Yapacağız",
        "Double": "Çift",
        "Your Funds": "Fonlarınız",
        "Unlimited Earnings": "Sınırsız Kazanç",
        "With Always": "Daima",
        "Growing Rates": "Büyüyen Oranlar",
        "We Provide investment conditions with growing percentage, depending on basic interest rate, smart-contract total balance bonus and personal": "Temel faiz oranına, akıllı sözleşme toplam bakiye bonusuna ve kişiye bağlı olarak artan yüzdeli yatırım koşulları sağlıyoruz.",
        "hold-bonus": "bekletme bonusu",
        "Maximal available profit": "Maksimum kullanılabilir kar",
        "+200% per every deposit": "Her depozito başına +% 200",
        "Reliability of Core": "Core'in güvenilirliği",
        "smart-contract.": "akıllı sözleşme.",
        "Scam is impossible!": "Dolandırıcılık imkansız!",
        "Smart-contract technology ensures full safety of all participants funds. Nobody can steal funds, or change contract functions.": "Akıllı sözleşme teknolojisi, tüm katılımcı fonlarının tam güvenliğini sağlar. Hiç kimse para çalamaz veya sözleşme işlevlerini değiştiremez.",
        "Worldwide legal": "Dünya çapında yasal",
        "company with": "şirket ile",
        "professional team": "profesyonel takım",
        "We are the officially registered company with team of trusted professionals on blockchain market. We are working 24/7 to increase platform popularity and make it truly worldwide.": "Blockchain pazarında güvenilir profesyonellerden oluşan bir ekiple resmi olarak tescilli bir şirketiz. Platform popülerliğini artırmak ve onu gerçekten dünya çapında yapmak için 7/24 çalışıyoruz.",
        "Investment conditions": "Yatırım koşulları",
        "the": "the",
        "best": "en iyi",
        "market offer": "piyasa teklifi",
        "Total invested amount (Core)": "Toplam yatırım tutarı (Core)",
        "Total active participants": "Toplam aktif katılımcılar",
        "Basic interest rate": "Temel faiz oranı",
        "+1% every 24 hours (+0.0416% hourly)": "Her 24 saatte bir +% 1 (saatlik +% 0,0416)",
        "CoreAvail smart-contract calculate profit up to every deposit since the date it was made. Every hour you will get": "CoreAvail akıllı sözleşme, yapıldığı tarihten itibaren her depozitoya kadar karı hesaplar. Her saat alacaksın",
        "every day": "her gün",
        "Personal hold-bonus": "Kişisel tutma bonusu",
        "+0.1% for every 24 hours without withdraw": "Para çekilmeden her 24 saatte +% 0,1",
        "Smart-contract calculates hold-bonus from your deposit, or last withdraw date. If you did not request payment, it will charge you an additional bonus. After 24 hours": "Akıllı sözleşme, depozitonuzdan veya son para çekme tarihinizden elde tutma bonusu hesaplar. Ödeme talep etmediyseniz, sizden ek bir bonus alır. 24 saat sonra",
        "after 48 hours": "48 saat sonra",
        "after 72 hours": "72 saat sonra",
        "and so on.": "ve bunun gibi.",
        "Contract total amount bonus": "Sözleşme toplam tutarı bonusu",
        "+0.1% for every 3,000,000 Core on platform balance": "Platform bakiyesinde her 3.000.000 Core için +% 0,1",
        "Smart-contract check its current balance and charge an additional": "Akıllı sözleşme mevcut bakiyesini kontrol edin ve ek ücret alın",
        "up to your earnings for every 3,000,000 Core on balance.": "bakiyedeki her 3.000.000 Core için kazancınıza kadar.",
        "CURRENT BONUS": "GÜNCEL BONUS",
        "Current platform balance (Core)": "Mevcut platform bakiyesi (Core)",
        "Honest": "Dürüst",
        "Transparent": "Şeffaf",
        "The": "The",
        "Best": "En iyi",
        "Market Offer": "Piyasa Teklifi",
        "CoreAvail platform smart-contract is published on Core blockchain. Nobody can change its rules or algorithms, even administration. This is provide our participants unconditional confidence in safety of their funds. Anyone can check smart-contract code and be sure that CoreAvail platform is honest.": "CoreAvail platformu akıllı sözleşmesi Core blok zincirinde yayınlandı. Hiç kimse kurallarını veya algoritmalarını değiştiremez, yönetimi bile. Bu, katılımcılarımıza fonlarının güvenliği konusunda koşulsuz güven sağlar. Herkes akıllı sözleşme kodunu kontrol edebilir ve CoreAvail platformunun dürüst olduğundan emin olabilir.",
        "Independent company": "Bağımsız şirket",
        "HAECHI": "HAECHI",
        "Website": "İnternet sitesi",
        "Audition status": "Audition durumu",
        "Successfully passed": "Başarıyla geçti",
        "Audit conclusion (PDF)": "Denetim sonucu (PDF)",
        "Smart-contract code": "Akıllı sözleşme kodu",
        "Reviews and mentionings": "Yorumlar ve sözler",
        "Last deposits": "Son mevduat",
        "Step #1": "Aşama 1",
        "Make deposit": "Mevduat yapmak",
        "Get": "Almak",
        "Core (CORE)": "Core (CORE)",
        "We recommend to use": "Kullanmanızı tavsiye ederiz",
        "browsers extensions, or": "tarayıcı uzantıları veya",
        "mobile apps. You can get Core coins via popular exchangers.": "Mobil uygulamalar. Core jetonlarını popüler değiştiriciler aracılığıyla alabilirsiniz.",
        "SEND": "GÖNDER",
        "Core TO OUR SMART-CONTRACT": "AKILLI SÖZLEŞMEMİZE Core",
        "Send any amount of Core on smart-contract address and start to get earnings every moment. Minimal deposit amount is": "Akıllı sözleşme adresine istediğiniz miktarda Core gönderin ve her an kazanç elde etmeye başlayın. Minimum para yatırma tutarı",
        "No maximal amount.": "Maksimum miktar yok.",
        "Step #2": "Adım 2",
        "Get earnings": "Kazanç elde edin",
        "Get your":"Alın",
        "Core every moment": "Core her an",
        "You can check all your wallet information, including your deposits, earnings, withdraw and referral statistics in real time.": "Para yatırma, kazanç, para çekme ve yönlendirme istatistikleri dahil tüm cüzdan bilgilerinizi gerçek zamanlı olarak kontrol edebilirsiniz.",
        "Additional earnings with referral program": "Tavsiye programıyla ek kazançlar",
        "3 levels of affiliate rewards": "3 seviyeli ortaklık ödülü",
        "Referral rewards comes instantly on your wallet balance and can be withdrawn any time along with earnings.": "Yönlendirme ödülleri anında cüzdan bakiyenize gelir ve kazançlarla birlikte istediğiniz zaman geri çekilebilir.",
        "Matching Commission of":"Eşleşen Komisyon",
        "on Partners Daily Income every time they make a withdrawal":"Partners Daily Income'da her para çekme işleminde",
        "1 new level is activated for each direct partner, maximum 10 levels.":"Her direkt partner için 1 yeni seviye etkinleştirilir, maksimum 10 seviye.",
        "Your Wallet Statistics": "Cüzdan İstatistikleriniz",
        "Step #3": "Aşama 3",
        "Request withdraw": "Para çekme talebinde bulunun",
        "Request": "İstek",
        "Core Withdraw": "Core Para Çekme",
        "Your payout will come": "Ödemeniz gelecek",
        "instantly": "anında",
        "Request withdraw from the": "Geri çekilme isteğinde bulunun",
        "same wallet": "aynı cüzdan",
        "you deposited": "yatırdın",
        "All your wallet deposits and referral earnings will be withdrawn with single transaction per 1 request": "Tüm cüzdan depozitolarınız ve tavsiye kazançlarınız, 1 istek başına tek işlemle geri çekilecektir.",
        "Payment of deposit": "Depozito ödemesi",
        "Deposit Bonus is paid daily throughout the term": "Depozito Bonusu dönem boyunca günlük olarak ödenir",
        "Body of the deposit is paid at the end of the term": "Depozito bedeli vade sonunda ödenir",
        "The Bonus and the deposit itself are transferred to your internal balance": "Bonus ve depozito, iç bakiyenize aktarılır",
        "Withdraw money from the balance to your wallet": "Bakiyeden cüzdanınıza para çekin",
        "You can make a new deposit and get more profit!": "Yeni bir para yatırıp daha fazla kar elde edebilirsiniz!",
        "Start Investment Now": "Yatırıma Hemen Başlayın",
        "Investment Conditions": "Yatırım Koşulları",
        "Basic Interest Rate": "Temel Faiz Oranı",
        "Every 24 Hours": "24 saatte bir",
        "Hourly": "Saatlik",
        "Personal hold-Bonus": "Kişisel tutma-Bonusu",
        "For Every 24 Hours Without Withdraw": "Para Çekmeden Her 24 Saat İçin",
        "Contract Total Amount Bonus": "Sözleşme Toplam Tutarı Bonusu",
        "For Every": "Her biri için",
        "Core  On Plateform Balance": "Plaka Formunda Core",
        "Testimonial": "Bonservis",
        "Join Us": "Bize katıl",
        "Understand Golff, communicate with the team, and join the communities to build a decentralized financial world together": "Golff'u anlayın, ekiple iletişim kurun ve birlikte merkezi olmayan bir finans dünyası kurmak için topluluklara katılın",
        "Our Representative": "Temsilcimiz",
        "Copyrights": "Telif hakları",
        "Blockchain investment platform": "Blockchain yatırım platformu",
        "All Rights Reserved": "Her hakkı saklıdır",
        "Smart-contract address": "Akıllı sözleşme adresi",
        "Powered by": "Tarafından desteklenmektedir",
        "Core blockchain": "Core blok zinciri",
        "Make New Deposit": "Yeni Para Yatır",
        "Connect with your wallet app...": "Cüzdan uygulamanıza bağlanın ...",
        "Connect with your": "İle bağlantı kurun",
        "browser extension, or": "tarayıcı uzantısı veya",
        "mobile app. After connection you will get opportunity to make deposit (reload this page).": "mobil uygulama. Bağlandıktan sonra para yatırma fırsatınız olacak (bu sayfayı yeniden yükleyin).",
        "Please, contact our support team if you cant connect.": "Bağlanamıyorsanız lütfen destek ekibimizle iletişime geçin.",
        "Your upline partner wallet": "Üst sıradaki ortak cüzdanınız",
        "No upline": "Üst sıra yok",
        "Specify deposit": "Depozito belirtin",
        "Core amount here": "Core tutarı burada",
        "Please enter amount": "Lütfen miktarı girin",
        "Minimum amount is 10 Core": "Minimum miktar 10 Core",
        "IMPORTANT!": "ÖNEMLİ!",
        "Do not forget about blockchain fee! You should have 2-5 Core more on your wallet, or your transaction will get out of energy status!": "Blockchain ücretini unutmayın! Cüzdanınızda 2-5 Core daha bulundurmalısınız, yoksa işleminiz enerji durumundan çıkacaktır!",
        "Minimal deposit amount": "Minimum para yatırma tutarı",
        "(no maximal limits)": "(maksimum sınır yok)",
        "We are working only with": "Sadece ile çalışıyoruz",
        "Core (CORE) cryptocurrency": "Core (CORE) kripto para birimi",
        "Your deposit will be activated after": "Depozitonuz şu tarihten sonra etkinleştirilecektir:",
        "1 confirmation": "1 onay",
        "in blockchain": "blok zincirinde",
        "Earnings comes every moment on your wallet balance, you can withdraw it any time you want": "Cüzdan bakiyenize her an kazanç gelir, istediğiniz zaman çekebilirsiniz",
        "Your": "Sizin",
        "Core wallet address": "Core cüzdan adresi",
        "Your referral link": "Yönlendirme bağlantınız",
        "Share this link with your partners to get referral rewards": "Tavsiye ödülleri almak için bu bağlantıyı ortaklarınızla paylaşın",
        "You should have active deposit to unlock affiliate bonuses! You will get no rewards without active deposit.": "Ortaklık bonuslarının kilidini açmak için aktif para yatırmanız gerekir! Aktif para yatırmadan hiçbir ödül almayacaksınız.",
        "Your current daily profit": "Mevcut günlük kârınız",
        "Basic profit": "Temel kar",
        "Hold-bonus": "Bekletme bonusu",
        "Contract bonus": "Sözleşme bonusu",
        "Total invested": "Toplam yatırım",
        "Total earned": "Toplam Kazanç",
        "Match bonus": "Maç bonusu",
        "Total withdrawn": "Toplam çekilen",
        "Referral rewards": "Yönlendirme ödülleri",
        "Number of deposits": "Mevduat sayısı",
        "Last deposit date": "Son para yatırma tarihi",
        "no deposits": "mevduat yok",
        "Available withdraw balance": "Mevcut para çekme bakiyesi",
        "Withdraw": "Çekil",
        "1st Level": "1. Seviye",
        "2nd Level": "2. Seviye",
        "3rd Level": "3. Seviye",
        "Your referral link": "Yönlendirme bağlantınız",
        "Copy referral link": "Yönlendirme bağlantısını kopyala",
        "Contest": "Yarışma",
        "COMING SOON!": "ÇOK YAKINDA!",
      }
    }
  },
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;
